import { interpret, Machine } from 'xstate'

const STARTING_POINT = 'start'

/**
 * (IS NOT USED on production, the api comes with a ready-made json for xstate)
 * jsonToXstates - parser to generate an object of states
 * @param {type: Object} obj - script data, valid object format:
 * ** obj: {
 * **   steps: [ - all states
 * **     id - state key
 * **   ],
 * **   connections: [ - action variants
 * **     source - state key from
 * **     target - state key to
 * **   ],
 * **   .. the rest doesn't matter
 * ** }
 * @return {type: Object} - object of states for xstate
 */
// export const jsonToXstates = obj => {
//   console.log('🐹 obj', obj)
//   const steps = obj.steps.map(e => e.id)
//   const connections = obj.connections.map(e => ({ from: e.source, to: e.target }))
//   const states = {}

//   steps.forEach(key => {
//     const variants = connections.filter(f => f.from === key)
//     if (variants.length > 0) {
//       const events = Object.fromEntries(variants.map(n => [n.to, n.to]))
//       states[key] = {
//         on: {...events},
//       }
//     } else {
//       states[key] = {}
//     }
//   })

//   return states
// }


/**
 * (we DO NOT USE on the production, it comes the api ready-made for xstate - starred_xstates)
 * setQuickTransitions - adding a quick transition to a global event
 * @param {type: Array} steps - all steps of the script
 * @return {type: Object} - global events
 */
// const setQuickTransitions = steps => {
//   const quickTransitionSteps = steps.filter(e => e.starred_text).map(e => e.id)
//   if (quickTransitionSteps.length > 0) {
//     const quickTransitions = {}
//     quickTransitionSteps.forEach(stepId => {
//       quickTransitions[stepId] = {
//         target: stepId,
//         internal: false,
//       }
//     })
//     return quickTransitions
//   }
//   return {}
// }


/**
 * initXstateMachine - initializing the state machine (without parsing in xstate)
 * @param {type: Object} json - server response
 * @return {type: Class} - state machine
 */
export const initXstateMachine = json => {
  const options = {
    id: json.id,
    context: {
      /* some data */
    },
    initial: STARTING_POINT,
    states: json.xstates,
    on: {
      ...json.starred_xstates,
      DONE: {
        target: '.start',
        internal: false, // external transition, will exit/reenter parent state node
      },
    },
  }

  /**
   * saving json to a file for visual inspection with  xstate visualizer
   */
  // if (process.env.NODE_ENV === 'development') {
  //   console.log('🐹 initXstateMachine', options)
  //   const downloadDevFile = (content, fileName, contentType) => {
  //     var a = document.createElement('a')
  //     var file = new Blob([content], { type: contentType })
  //     a.href = URL.createObjectURL(file)
  //     a.download = fileName
  //     a.click()
  //   }
  //   downloadDevFile(JSON.stringify(options, null, '  '), `dev-file-${Date.now()}.json`, 'text/plain')
  // }

  return interpret(Machine(options))
}

