import idb from '../../idb.js'


export default {
  namespaced: true,
  state: () => ({
    starredScripts: [],
  }),

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    GET_STARRED_SCRIPTS(state, payload) {
      const { starredScripts } = payload
      state.starredScripts = starredScripts
    },
    ADD_STARRED_SCRIPT(state, payload) {
      const { script } = payload
      if (!state.starredScripts.some(s => s === script)) {
        state.starredScripts.push(script)
      }
    },
    REMOVE_STARRED_SCRIPT(state, payload) {
      const { script } = payload
      state.starredScripts = state.starredScripts.filter(s => s !== script)
    },
  },


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    async GET_STARRED_SCRIPTS({ commit, dispatch, rootGetters }) {
      try {
        const userId = rootGetters.auth.userInfo.id
        if (rootGetters.auth.isAuthorized && userId) {
          dispatch('app/SET_IS_WAITING_STATUS', true, { root: true })
          const starredScripts = await idb.getStarredScripts(userId)
          commit('GET_STARRED_SCRIPTS', {starredScripts})
          dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
        }
      } catch (err) {
        console.error('🐹 starredScripts/GET_STARRED_SCRIPTS', err)
        dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
      }
    },
    async ADD_STARRED_SCRIPT({ commit, dispatch, rootGetters }, payload) {
      const { script } = payload
      try {
        const userId = rootGetters.auth.userInfo.id
        if (rootGetters.auth.isAuthorized && userId) {
          dispatch('app/SET_IS_WAITING_STATUS', true, { root: true })
          await idb.setStarredScript(userId, script)
          commit('ADD_STARRED_SCRIPT', { script })
          dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
        }
      } catch (err) {
        console.error('🐹 starredScripts/GET_STARRED_SCRIPTS', err)
        dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
      }
    },
    async REMOVE_STARRED_SCRIPT({ commit, dispatch, rootGetters }, payload) {
      const { script } = payload
      try {
        const userId = rootGetters.auth.userInfo.id
        if (rootGetters.auth.isAuthorized && userId) {
          dispatch('app/SET_IS_WAITING_STATUS', true, { root: true })
          await idb.deleteStarredScript(userId, script)
          commit('REMOVE_STARRED_SCRIPT', { script })
          dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
        }
      } catch (err) {
        console.error('🐹 starredScripts/GET_STARRED_SCRIPTS', err)
        dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
      }
    },
  },
}
