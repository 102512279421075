import EntryPoint from '../views/entrypoint.vue'
import Login from '../views/login.vue'
import Scripts from '@/core/list/views/scripts.vue'
import Pass from '@/core/pass/views/pass.vue'

const routes = [
  {
    path: '/wde/login',
    name: 'wdeLogin',
    component: Login,
  },
  {
    path: '/wde',
    component: EntryPoint,
    children: [
      {
        path: '',
        name: 'wdeScripts',
        components: {core: Scripts},
      },
      {
        path: 'script/:id',
        name: 'wdePass',
        components: {core: Pass},
      },
    ],
  },
]

export default routes
