import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'

Vue.use(VueRouter)

let integrationRoutes = []
const r = require.context('@/integrations/', true, /^(.+?)\/(router)\/(routes.js)$/)
r.keys().forEach((key) => (integrationRoutes = [...integrationRoutes, ...r(key).default]))

const defaultRoute = [
  // 404 page must be placed at the end !!!
  {path: '*', redirect: '/', hidden: true},
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...integrationRoutes, ...defaultRoute],
})

// router.beforeEach((to, from, next) => {
//   console.debug(to, from)
//   next()
// })
export default router
