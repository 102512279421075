export const integrationTypes = {
  DEMO: 'demo',
  IFRAME: 'iframe',
  PIPEDRIVE: 'pipedrive',
  WDE: 'wde',
  DEFAULT: 'default',
}

export default {
  namespaced: true,
  state: () => ({
    integrationType: integrationTypes.DEFAULT,
  }),

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    SET_INTEGRATION_TYPE(state, payload) {
      state.integrationType = payload
    },
  },

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    SET_INTEGRATION_TYPE({ commit }, payload) {
      const { type } = payload
      try {
        const validTypes = Object.values(integrationTypes)
        if (!validTypes.includes(type)) {
          throw new Error('integration type is not valid')
        }
        commit('SET_INTEGRATION_TYPE', type)
      } catch (err) {
        console.error('🐹 integrationType/SET_INTEGRATION_TYPE', err)
      }
    },
  },
}
