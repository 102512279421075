<template>
  <div class="fragment">
    <transition-group name="fade" tag="div">
      <preloader v-show="isPreload" key="preload" />
      <logout-preloader v-show="isLogoutPreload" key="logoutPreload" />
      <main v-show="!isPreload && !isLogoutPreload" id="app" key="main" class="relative w-full h-screen flex bg-white">
        <!-- main body -->
        <main-sidebar v-if="!loginOnly" />
        <main-active-sidebar v-if="!loginOnly" :class="{'is-open': isOpenMainSidebar}" />
        <login-sidebar ref="loginSidebar" :class="{'is-open': isOpenLoginSidebar}" :login-only="loginOnly" :integration-type="integrationType" />
        <div v-if="!loginOnly" id="router-view" class="relative w-full max-h-screen flex-1 overflow-x-hidden">
          <router-view name="core" :integration-type="integrationType" />
        </div>
        <!-- /main body -->
        <transition name="fade">
          <div
            v-if="isOpenLoginSidebar || isOpenMainSidebar"
            class="fixed z-30 top-0 left-0 bottom-0 right-0"
            style="background: rgba(0,0,0,.5)"
            @click="$store.dispatch('app/TOGGLE_MAIN_SIDEBAR', false)"
          />
        </transition>
      </main>
    </transition-group>
  </div>
</template>

<script>
import MainSidebar from '@/core/application/components/sidebars/main-sidebar.vue'
import MainActiveSidebar from '@/core/application/components/sidebars/main-active-sidebar.vue'
import LoginSidebar from '@/core/application/components/sidebars/login-sidebar.vue'
import Preloader from '@/core/application/components/preloaders/preloader.vue'
import LogoutPreloader from '@/core/application/components/preloaders/logout-preloader.vue'

// modules
import { notificationBus } from '../../infrastructure/notification-bus/notification-bus.js'
import {
  initSocket,
  closeSocket,
} from '../../infrastructure/socket/socket.js'
import idb from '../../infrastructure/storage/idb.js'
// eslint-disable-next-line no-unused-vars
import {messagingBus, onSignedIn, onReady} from '../../infrastructure/messaging/messaging.js'
export default {
  name: 'Application',
  components: {
    MainSidebar,
    MainActiveSidebar,
    LoginSidebar,
    Preloader,
    LogoutPreloader,
  },
  props: {
    loginOnly: {
      type: Boolean,
      default: false,
    },
    integrationType: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isPreload: true,
    isLogoutPreload: false,
    isOnline: true,
    isNetworkError: false,
  }),
  computed: {
    isOpenLoginSidebar() {
      return this.$store.getters.app.loginSidebar.isOpen
    },
    isOpenMainSidebar() {
      return this.$store.getters.app.mainSidebar.isOpen
    },
    userInfo() {
      return this.$store.getters.auth.userInfo || {}
    },
    isAuthorized() {
      return this.$store.getters.auth.isAuthorized || false
    },
  },
  watch: {
    // TODO separate using events
    isOnline(val) {
      window._isOnline = val
    },
    isAuthorized(val) {
      if (val) {
        this.$emit('on-sign-in', this.userInfo)
        onSignedIn({
          id: this.userInfo.id,
          username: this.userInfo.username,
          lastName: this.userInfo.last_name,
          firstName: this.userInfo.first_name,
        })
      }
    },
  },
  created() {
    window._isOnline = this.isOnline

    notificationBus.$on('notify', obj => {
      const { title, message, type } = obj
      const msg = typeof message === 'string'
        ? message
        : message.t
      const vars = typeof message === 'string'
        ? {}
        : message.v
      this.$notify[type]({
        title: this.$t(title),
        dangerouslyUseHTMLString: true,
        message: this.$t(msg, vars),
        duration: 4000,
      })
    })

    this.$notify.success({
      title: 'HyperScript',
      message: this.$t('connection.restored') + '.',
      dangerouslyUseHTMLString: true,
      duration: 50,
    })

    this.$root.$on('set-online-status', status => {
      console.log('🐹 set-online-status', status)
      if (status === false) {
        this.isNetworkError = true
        if (this.isOnline === true) {
          this.isOnline = false
          closeSocket()
          this.messageIsOffline()
        }
      } else {
        this.isNetworkError = false
        this.isOnline = true
        this.messageIsOnline()
      }
    })
    onReady()
  },
  mounted() {
    this.initFeatureToggling()
    this.searchScrollWidth()
    /**
     * Check internet connection
     */
    setInterval(() => {
      if(!this.isOnline && navigator.onLine && !this.isNetworkError) {
        this.isOnline = true
        this.messageIsOnline()
        if (this.isAuthorized) {
          this.$store.dispatch('cache/PUSH_BACKUPS')
          closeSocket()
          initSocket(this.userInfo.id, this.$refs.loginSidebar)
        }
      }
      else if(this.isOnline && !navigator.onLine && !this.isNetworkError) {
        this.isOnline = false
        this.messageIsOffline()
        if (this.isAuthorized) {
          closeSocket()
        }
      }
    }, 1000)

    /**
     * Sending pass queue from IndexDB
     */
    setInterval(async() => {
      if (this.isAuthorized === true && this.isOnline === true) {
        const backupPass = await idb.getBackups()
        const onlyMyPass = backupPass.filter(e => e.data.user_id === this.userInfo?.id)
        if (onlyMyPass.length > 0) {
          console.debug('[🥑 TICK: processing pass queue from IndexDB]')
          console.debug('\t 🥑 SENDING TO THE SERVER...', onlyMyPass)
          await this.$store.dispatch('cache/PUSH_BACKUPS')
        }
      }
    }, 30000)
  },
  methods: {
    /**
     * searchScrollWidth() - ui trick to find the width of the scroll
     */
    searchScrollWidth() {
      // create a scrolling element
      const div = document.createElement('div')
      div.style.overflowY = 'scroll'
      div.style.width = '50px'
      div.style.height = '50px'
      // we have to insert the element into the document, otherwise the dimensions will be 0
      document.body.append(div)
      const scrollWidth = div.offsetWidth - div.clientWidth
      this.$store.dispatch('app/SET_SCROLL_WIDTH', scrollWidth)
      // delete block
      div.remove()
    },

    hidePreloader() {
      this.isPreload = false
      this.$root.$emit('fixCurrentTabActiveInScripts')
      this.$root.$emit('fixCurrentTabActiveInPassToolbar')
    },
    toggleLogoutPreloader(bool) {
      this.isLogoutPreload = bool
      if (bool === false) {
        this.$store.dispatch('app/TOGGLE_MAIN_SIDEBAR', false)
        this.$store.dispatch('app/TOGGLE_LOGIN_SIDEBAR', true)
      }
    },
    messageIsOffline() {
      this.$notify.warning({
        title: 'HyperScript',
        message: this.$t('connection.lost'),
        dangerouslyUseHTMLString: true,
        duration: 8000,
      })
    },

    messageIsOnline() {
      this.$notify.success({
        title: 'HyperScript',
        message: this.$t('connection.restored') + '.',
        dangerouslyUseHTMLString: true,
        duration: 8000,
      })
    },
  },
}
</script>

<style scoped>

</style>
