export const generateXstateSteps = (script) => {
  script = JSON.parse(JSON.stringify(script))

  if (script.steps.length == 0 || script.connections.length == 0) return {}
  const steps = script.steps.reduce((arr, step) => ({...arr, ...{[step.id] : {on: {}}}}), {})
  const states = script.connections.reduce((steps, connection) => {
    steps[connection.source].on[connection.target] = connection.target
    return steps
  }, steps)
  return states
}

export const generateStarredXstate = (script) => {
  script = JSON.parse(JSON.stringify(script))
  if (script.steps.length == 0 || script.connections.lenght == 0) return {}
  let starredSteps = []
  for (const category of script.starred) {
    if (Array.isArray(category.s)) {
      starredSteps = [...starredSteps, ...category.s]
    }
  }
  starredSteps.filter(starredStep => {
    for (const step of script.steps) {
      if (step.id == starredStep) {
        return step.is_starred
      }
    }
      return false
  })

  const states = {}
  for (const starredStep of starredSteps) {
    states[starredStep] = {
      target: starredStep,
      internal: false,
    }
  }
  return states
}

export const generateStarredForDemo = (script) => {
  script.data.steps.forEach(step => step.is_starred = step.id === 's_demo_end' ? false : true )
  script.data.starred = [{
    n: 'Быстрые переходы',
    s: script.data.steps.map(step => step.id),
  }]
}

export const addValueFieldsToDemoScript = (script) => {
  Object.values(script.fields).forEach(field => field.value = '')
}

export const fixEmptyConnections = (script) => {
  if(!script.connections) script.connections = [{source: 'start', target: 'start', condition: 'Ответ'}]
  if(script.steps.length == 1) {
    if(!script.steps[0].text.match(/^<p>/)) {
      script.steps[0].text = `<p>${script.steps[0].text}</p>`
    }
  }
}

/**
 * Converts HTML step of the script to JSON
 * @param {Object} script
 */
export const convertScriptStepsToJSON = (script) => {
  if (script.steps.length === 0) return
    for (let step of script.steps) {
      if (!step.text) {
          step.text = []
          continue
      }
      const stepHtml = step.text
      const domParser = new DOMParser()
      const dom = domParser.parseFromString(stepHtml, 'text/html')
      const errorNode = dom.querySelector('parsererror')
      if (errorNode) {
        console.error(errorNode, 'error node')
      } else {
        // console.log('success')
      }
      const elementsInObj = [...dom.body.children].map(el => elementToObj(el))
      step.text = elementsInObj
    }
}

const elementToObj = (element, level = 0) => {
    const obj = { e: element.tagName.toLowerCase() }
    for (let attr of element.attributes) {
      if (!['style'].includes(attr.name)) {
          switch (attr.name) {
              case 'class':
                  obj['@c'] = attr.value
                  break
              case 'data-id':
                  obj['@d'] = attr.value
                  break
              case 'src':
                  obj['@s'] = attr.value
                  break
              case 'alt':
                  obj['@a'] = attr.value
                  break
              case 'href':
                  obj['@h'] = attr.value
                  break
              default:
                  obj[attr.name] = attr.value
          }
      }
    }
    level++
    obj._ = []
    for (let child of element.childNodes) {
        if (level > 50) {
            obj._.push({
                t: child.textContent,
            })
        } else {
            if (child.nodeType === child.TEXT_NODE) {
                obj._.push({
                    t: child.wholeText,
                })
            } else {
                obj._.push(elementToObj(child, level))
            }
        }
    }
    return obj
}

