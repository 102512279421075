import Vue from 'vue'

Vue.component('dynamic-tag', {
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  render(createElement) {
    const customClass = []
    const customStyle = []
    if (this.item['@c']) {
      customClass.push(this.item['@c'])
    }
    if (this.tag === 'img') {
      customClass.push('dynamic-media')
    }
    if (this.tag === 'a') {
      customStyle.push('color: blue; text-decoration: underline;')
    }
    customClass.push('mb-2')

    return createElement(
      this.tag,
      {
        attrs: {
          style: customStyle.join(' '),
          class: customClass.join(' '),
          src: this.item['@s'],
          alt: this.item['@a'],
          href: this.item['@h'],
          target: this.item.target,
        },
      },
      this.$slots.default,
    )
  },
})
