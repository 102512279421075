const mixin = {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    setValue(event) {
      this.setPlainValue(event.target.value)
    },
    setPlainValue(val) {
      this.$emit('set-value', val)
    },
    clearValue() {
      this.$emit('set-value', '')
    },
  },
}

export default mixin
