/* eslint-disable */
import {reactive, computed, onMounted } from 'vue' 

export const useTabPanel = () => {
    const tabPanelState = reactive({
        current: 0,
        indicator_width: 0,
        indicator_pos: 0,
        forwards: true,
    })
    
    const getTabIndicatorStyle = computed(() => {
      return {
        left: tabPanelState.indicator_pos + 'px',
        width: tabPanelState.indicator_width + 'px',
      }
    })
    
    const chooseDirectionForTab = computed(() => {
      return tabPanelState.forwards ? 'tab' : 'tab-back'
    })
    
    const changeTab = (index) => {
      const prev = tabPanelState.current
      if (prev > index) {
        tabPanelState.forwards = false
      } else {
        tabPanelState.forwards = true
      }
      tabPanelState.current = index
    }
    
    const toggleTab = (tab, index) => {
      tabPanelState.indicator_width = tab.indicator_width
      tabPanelState.indicator_pos = tab.indicator_pos
      changeTab(index)
    }
  
    return {
      tabPanelState,
      getTabIndicatorStyle,
      chooseDirectionForTab,
      toggleTab,
    }
}