import Vue from 'vue'

import {
  GetScripts,
  GetScript,
  PostPassLog,
  SendBeacon,
} from '../../backend-endpoints.js'

import idb from '../../idb.js'
import { notificationBus } from '../../../notification-bus/notification-bus.js'

import { initXstateMachine } from '../../x-state-machine.js'
const initMachineHelper = script => {
  const { id, data } = script
  const { xstates, steps, starred_xstates } = data
  return initXstateMachine({ id, xstates, steps, starred_xstates })
}

export default {
  namespaced: true,
  state: () => ({
    currentScript: {},
    currentWorkspace: {},
    startTime: null,
    titleName: null,
    mainScriptUid: null,
    //
    trigger: null,
    triggerScriptId: null,
    source: 'go',
  }),


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    SET_CURRENT_SCRIPT_WITHOUT_INIT_MACHINE(state, payload) {
      const { data, isSubScript, source } = payload

      const script = data
      const key = `${Date.now()}_${script.id}`
      script._uid = key
      Vue.set(state.currentWorkspace, key, script)
      state.currentScript = state.currentWorkspace[key]
      state.source = source

      if (!isSubScript) {
        state.startTime = Date.now()
        state.titleName = data.name
        state.mainScriptUid = key
      }

      const clone = JSON.parse(JSON.stringify(data))
      // clear all fields before save in indexDB
      const fieldKeys = Object.keys(clone.fields)
      fieldKeys.forEach(fieldKey => {
        clone.fields[fieldKey].value = ''
      })
      idb.setOpenedScript(clone)
    },
    SET_CURRENT_SCRIPT_WITH_INIT_MACHINE(state, payload) {
      const { data, isSubScript, source } = payload

      const script = data
      const key = `${Date.now()}_${script.id}`
      script._uid = key
      Vue.set(state.currentWorkspace, key, script)
      state.currentScript = state.currentWorkspace[key]
      state.source = source

      if (!isSubScript) {
        state.startTime = Date.now()
        state.titleName = data.name
        state.mainScriptUid = key
      }

      // init xstate machine
      Vue.set(state.currentScript, '_xMachine', initMachineHelper(state.currentScript))

      const clone = JSON.parse(JSON.stringify(data))
      // clear all fields before save in indexDB
      const fieldKeys = Object.keys(clone.fields)
      fieldKeys.forEach(fieldKey => {
        clone.fields[fieldKey].value = ''
      })
      idb.setOpenedScript(clone)
    },
    CHANGE_FIELD(state, payload) {
      const { id, value, _uid } = payload
      state.currentWorkspace[_uid].fields[id].value = value
    },
    CLEAR_CURRENT_WORKSPACE(state) {
      state.currentScript = {}
      state.currentWorkspace = {}
      state.startTime = null
      state.titleName = null
      state.mainScriptUid = null
    },
    CLEAR_ALL_VALUES(state) {
      const keys = Object.keys(state.currentWorkspace)
      keys.forEach(uid => {
        const fieldKeys = Object.keys(state.currentWorkspace[uid].fields)
        fieldKeys.forEach(fieldId => {
          state.currentWorkspace[uid].fields[fieldId].value = ''
        })
      })
    },
    CHANGE_TRIGGER_STATUS(state, payload) {
      if (payload === null) {
        state.trigger = null
        state.triggerScriptId = null
      } else {
        const { event, id } = payload
        state.trigger = event
        state.triggerScriptId = id
      }
    },
    CHANGE_CURRENT_SCRIPT(state, payload) {
      const { _uid, toggleClearWorkspace } = payload
      if (state.currentWorkspace[_uid]._uid !== _uid) {
        state.currentWorkspace[_uid]._uid = _uid
      }
      state.currentScript = state.currentWorkspace[_uid]
      if (toggleClearWorkspace === true) {
        const workspaceKeys = Object.keys(state.currentWorkspace)
        let i = workspaceKeys.length - 1
        while (i !== 0) {
          delete state.currentWorkspace[workspaceKeys[i]]
          i--
        }
      }
    },
  },


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    /**
     * GET_SCRIPTS() - request for scripts
     */
    async GET_SCRIPTS({ dispatch, rootGetters }) {
      console.log('🐹 cache/GET_SCRIPTS')
      window.last_action_link = { name: 'GET_SCRIPTS' }
      try {
        if (rootGetters.auth.isAuthorized) {
          dispatch('app/SET_IS_WAITING_STATUS', true, { root: true })
          const response = await GetScripts()
          const localScripts = await dispatch('UNPACK_OPENED_SCRIPTS', response.data)
          dispatch('scripts/GET_SCRIPTS', { remoteScripts: response.data, localScripts }, { root: true })
          dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
          window.last_action_link = {}
        }
      } catch (err) {
        console.error('🐹 cache/GET_SCRIPTS', err)
      }
    },

    // eslint-disable-next-line
    async UNPACK_OPENED_SCRIPTS({ commit }, payload) {
      const openedScripts = await idb.getOpenedScripts()
      const myScripts = openedScripts.filter(e => payload.some(s => s.id === e.id))
      const withoutOldScripts = myScripts.filter(e => {
        const find = payload.find(script => script.id === e.id)
        if (find && find.updated === e.updated) {
          return true
        } else {
          idb.deleteOpenedScript(e)
          return false
        }
      })
      const scripts = withoutOldScripts.map(script => ({
        ...script,
        _xMachine: initMachineHelper(script),
      }))
      return scripts
    },


    /**
     * GET_CURRENT_SCRIPT() - request to retrieve data from a particular script
     * @param {Number|String} payload - this.$route.params.id
     */
    async GET_CURRENT_SCRIPT({ dispatch , rootGetters }, payload) {
      // console.log('🐹 cache/GET_CURRENT_SCRIPT', payload)
      const { id, isSubScript, source, vm } = payload
      window.last_action_link = { name: 'GET_CURRENT_SCRIPT', payload }
      dispatch('app/SET_IS_WAITING_CURRENT_SCRIPT_STATUS', true, { root: true })
      if (vm && vm.isFeatureToggled(vm && vm.appFeatures.FEATURE_DEMO_SCRIPT)) {
        const script = rootGetters.demoScript.script
        dispatch('SET_CURRENT_SCRIPT', { data: script, source: source })
        dispatch('app/SET_IS_WAITING_CURRENT_SCRIPT_STATUS', false, { root: true })
      } else {
        if (rootGetters.scripts.scripts.length === 0 && !isSubScript) {
          await dispatch('GET_SCRIPTS')
        }
        try {
          const openedScripts = rootGetters.scripts.openedScripts
          const find = openedScripts.find(script => script.id === parseInt(id, 10))
          if (find) {
            dispatch(isSubScript ? 'SET_SUB_SCRIPT' : 'SET_CURRENT_SCRIPT', { data: find, withoutInit: true, source: source })
          } else {
            const response = await GetScript(id)
            response.data.updated = parseInt(response.data.updated, 10)
            dispatch(isSubScript ? 'SET_SUB_SCRIPT' : 'SET_CURRENT_SCRIPT', { data: response.data, source: source })
          }
          window.last_action_link = {}
          dispatch('app/SET_IS_WAITING_CURRENT_SCRIPT_STATUS', false, { root: true })
        } catch (err) {
          console.error('🐹 cache/GET_CURRENT_SCRIPT', err)
        }
      }
    },


    SET_CURRENT_SCRIPT({ commit, state }, payload) {
      try {
        Vue.set(payload.data, '_last_view', Date.now())
        if (payload.withoutInit) {
          commit('SET_CURRENT_SCRIPT_WITHOUT_INIT_MACHINE', { data: payload.data, source: payload.source })
          commit('scripts/UPDATE_LAST_VIEW', payload.data, { root: true })
        } else {
          commit('SET_CURRENT_SCRIPT_WITH_INIT_MACHINE', { data: payload.data, source: payload.source })
          commit('scripts/ADD_OPENED_SCRIPT', state.currentScript, { root: true })
        }
      } catch (err) {
        console.error('🐹 cache/SET_CURRENT_SCRIPT', err)
      }
    },


    SET_SUB_SCRIPT({ commit, state }, payload) {
      try {
        Vue.set(payload.data, '_last_view', Date.now())
        if (payload.withoutInit) {
          commit('SET_CURRENT_SCRIPT_WITHOUT_INIT_MACHINE', { data: payload.data, isSubScript: true, source: payload.source })
          commit('scripts/UPDATE_LAST_VIEW', payload.data, { root: true })
        } else {
          commit('SET_CURRENT_SCRIPT_WITH_INIT_MACHINE', { data: payload.data, isSubScript: true, source: payload.source })
          commit('scripts/ADD_OPENED_SCRIPT', state.currentScript, { root: true })
        }
      } catch (err) {
        console.error('🐹 cache/SET_SUB_SCRIPT', err)
      }
    },

    CHANGE_CURRENT_SCRIPT({ commit }, payload) {
      commit('CHANGE_CURRENT_SCRIPT', payload)
    },


    CREATED_SCRIPT({ dispatch }, payload) {
      dispatch('scripts/CREATED_SCRIPT', payload, { root: true })
    },


    async UPDATE_SCRIPT({ state, dispatch, rootGetters, commit }, payload) {
      const { id, updatedCurrentScript } = payload
      window.last_action_link = { name: 'UPDATE_SCRIPT_CATCH', payload }

      if (state.trigger === 'update' && updatedCurrentScript) {
        console.log('!!!🐹 UPDATE CURRENT SCRIPT !!!')
        dispatch('CLEAR_CURRENT_WORKSPACE')
        dispatch('app/SET_IS_WAITING_STATUS', true, { root: true })
        const response = await GetScript(id)
        const script = {
          ...response.data,
          _xMachine: initMachineHelper(response.data),
        }
        dispatch('scripts/UPDATE_SCRIPT', { script }, { root: true })
        await dispatch('GET_CURRENT_SCRIPT', payload)
        commit('CHANGE_TRIGGER_STATUS', null)
        window.last_action_link = {}
        dispatch('app/SET_IS_WAITING_STATUS', false, { root: true })
      } else {
        try {
          if (state.currentScript.id === id) {
            commit('CHANGE_TRIGGER_STATUS', { event: 'update', id: state.currentScript.id })
            window.last_action_link = {}
          } else {
            const find = rootGetters.scripts.openedScripts.find(script => script.id === id)
            if (find) {
              const response = await GetScript(id)
              const script = {
                ...response.data,
                _xMachine: initMachineHelper(response.data),
              }
              dispatch('scripts/UPDATE_SCRIPT', { script }, { root: true })
            } else {
              const fakeScript = rootGetters.scripts.scripts.find(script => script.id === id)
              fakeScript.name = payload.name
              notificationBus.notify({ title: 'HyperScript', message: { t: 'socketCallbackMessage.updatedSuccess', v: { name: fakeScript.name } }, type: 'success' })
            }
            commit('CHANGE_TRIGGER_STATUS', null)
            window.last_action_link = {}
          }
        } catch (err) {
          console.error('🐹 cache/UPDATE_SCRIPT', err)
        }
      }
    },


    async UPDATE_SCRIPT_CATCH({ dispatch }, payload) {
      const { id } = payload
      window.last_action_link = { name: 'UPDATE_SCRIPT_CATCH', payload }

      try {
        const response = await GetScript(id)
        const script = {
          ...response.data,
          _xMachine: initMachineHelper(response.data),
        }
        dispatch('scripts/UPDATE_SCRIPT', { script }, { root: true })
      }
      catch (err) {
        console.error('🐹 cache/UPDATE_SCRIPT_CATCH', err)
      }
    },


    async REMOVE_SCRIPT({ dispatch, state, commit }, payload) {
      console.log('🐹 cache/REMOVE_SCRIPT', payload)
      const { id } = payload
      await idb.deleteOpenedScript({ id })
      if (state.currentScript.id === id && state.trigger === null) {
        commit('CHANGE_TRIGGER_STATUS', { event: 'remove', id: state.currentScript.id })
      } else {
        dispatch('scripts/REMOVE_SCRIPT', { id }, { root: true })
        commit('CHANGE_TRIGGER_STATUS', null)
      }
    },


    CLEAR_CURRENT_WORKSPACE({ commit, dispatch }) {
      commit('CLEAR_CURRENT_WORKSPACE')
      commit('CLEAR_ALL_VALUES')
      dispatch('pass/CLEAR_PASS', {}, { root: true })
    },


    async PASS_DONE({ commit, dispatch, rootGetters }) {
      console.debug('🐹 cache/PASS_DONE')
      window.last_action_link = {}

      dispatch('app/SET_IS_WAITING_PASS_CALLBACK', true, { root: true })
      dispatch('pass/DONE', {}, { root: true })

      const passStack = rootGetters.pass.passStack
      const backup = JSON.parse(JSON.stringify(passStack))
      dispatch('pass/CLEAR_PASS', {}, { root: true })


      const mergePass = []

      for (let i = Object.keys(backup).length; i--; i !== 0) {
        const onePass = Object.entries(backup)[i]
        const script_id = onePass[0].split('_')[1]
        let someOne = mergePass.some(e => e.script_id === script_id)
        if (!someOne) {
          mergePass.push({
            script_id,
            data: onePass[1],
          })
        } else {
          const f = mergePass.find(e => e.script_id === script_id)
          f.data.log = [...onePass[1].log, ...f.data.log]
        }
      }

      const arr = mergePass.map((pass, idx) => ({
        id: Date.now() + idx,
        data: pass.data,
      }))

      commit('CLEAR_ALL_VALUES')

      arr.forEach(async(e) => {
        await idb.setBackup(e)
      })

      setTimeout(() => {
        dispatch('app/SET_IS_WAITING_PASS_CALLBACK', false, { root: true })
      }, 0)
    },

    PASS_SEND_BEACON({ dispatch, rootGetters }) {
      console.debug('🐹 cache/PASS_SAVE_BEACON')

      dispatch('app/SET_IS_WAITING_PASS_CALLBACK', true, { root: true })
      dispatch('pass/DONE', {}, { root: true })

      const passStack = rootGetters.pass.passStack
      const backup = JSON.parse(JSON.stringify(passStack))

      dispatch('pass/CLEAR_PASS', {}, { root: true })

      const mergePass = []

      for (let i = Object.keys(backup).length; i--; i !== 0) {
        const onePass = Object.entries(backup)[i]
        const script_id = onePass[0].split('_')[1]
        let someOne = mergePass.some(e => e.script_id === script_id)
        if (!someOne) {
          mergePass.push({
            script_id,
            data: onePass[1],
          })
        } else {
          const f = mergePass.find(e => e.script_id === script_id)
          f.data.log = [...onePass[1].log, ...f.data.log]
        }
      }

      const arr = mergePass.map((pass, idx) => ({
        id: Date.now() + idx,
        data: pass.data,
      }))

      for (const scriptLog of arr) {
        SendBeacon(scriptLog.data)
      }
    },

    CHANGE_FIELD({ commit }, payload) {
      commit('CHANGE_FIELD', payload)
    },

    async PUSH_BACKUPS({ rootGetters }) {
      const backupPass = await idb.getBackups()
      const promises = backupPass.filter(e => e.data.user_id === rootGetters.auth.userInfo?.id).map(e => PostPassLog(e.data))
      const results = await Promise.allSettled(promises)
      results.forEach((e, idx) => {
        if (e.status === 'fulfilled') {
          idb.deleteBackup(backupPass[idx])
        }
      })
    },
  },
}
