import Vue from 'vue'
import Vuex from 'vuex'

// modules
import app from '../core/application/store/app.js'
import auth from '../core/application/store/auth.js'
import clipboard from '../core/infrastructure/clipboard/store/clipboard.js'
import cache from '../core/infrastructure/storage/store/business-logic/cache.js'
import pass from '../core/pass/store/business-logic/pass.js'
import scripts from '../core/infrastructure/storage/store/business-logic/scripts.js'
import starredScripts from '../core/infrastructure/storage/store/business-logic/starred-scripts.js'
import stepContextMenu from '../core/pass/store/step-context-menu.js'
import featureToggling from '../core/application/store/business-logic/feature-toggling.js'
import demoScript from '../core/infrastructure/storage/store/demo-script.js'
import integrationType from '../integrations/store/integration-type.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    clipboard,
    cache,
    pass,
    scripts,
    starredScripts,
    stepContextMenu,
    featureToggling,
    demoScript,
    integrationType,
  },
  getters: {
    app: state => state.app,
    auth: state => state.auth,
    clipboard: state => state.clipboard,
    cache: state => state.cache,
    pass: state => state.pass,
    scripts: state => state.scripts,
    starredScripts: state => state.starredScripts,
    stepContextMenu: state => state.stepContextMenu,
    featureToggling: state => state.featureToggling,
    demoScript: state => state.demoScript,
    integrationType: state => state.integrationType,
  },
})

export default store
