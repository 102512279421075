<template>
  <span
    class="hs-field-in-step field-material ring-0 ring-hs-primary bg-white"
    :class="{
      'ring-2': isFocus
    }"
    :style="{
      'padding-top': '17px', 
      'border': '1px solid #f4f4f4',
      'border-radius': '10px'
    }"
  >
    <textarea
      :id="id"
      class="textarea"
      :style="{'padding-top': '0', 'border': 'none', 'min-height': '42px'}"
      :value="value"
      placeholder=""
      @focus="isFocus = true"
      @blur="isFocus = false"
      @input="setValue($event)"
    />
    <label
      :for="id"
      class="label"
      :class="{'is-focus': isFocus || valueIsNotEmpty}"
    >
      {{ placeholder }}
    </label>
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'
export default {
  mixins: [FieldBaseMixin],
  data() {
    return {
      isFocus: this.value.length > 0,
    }
  },
  computed: {
    valueIsNotEmpty() {
      return this.value ? this.value.length > 0 : false
    },
  },
}
</script>
<style scoped>
  .label {
    transition: all 0.3s;
    pointer-events: none;
    top: 18px!important;
    left: 20px!important;
  }
  
  label.is-focus {
    top: 5px!important;
  }
  .textarea:focus {
    box-shadow: none!important;
  }
</style>
