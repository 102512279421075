<template>
  <router-view />
</template>


<script>
export default {
  methods: {},
}
</script>
