import { render, staticRenderFns } from "./pass-text.vue?vue&type=template&id=cafe9580"
import script from "./pass-text.vue?vue&type=script&lang=ts"
export * from "./pass-text.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports