import api from '../../utils/axios.js'
import axios from 'axios'
const demoScriptBaseUrl = process.env.VUE_APP_AJAX_URL

const baseURL = process.env.VUE_APP_PASS_API

export const GetScripts = () => api.get('/api/scripts')
export const GetScript = id => api.get(`/api/scripts/${id}`)
export const GetDemoScript = (id) => {

  const formData = new FormData()
  formData.append('method', 'api.getIntegrationDemoScript')
  formData.append('apikey', 'test_api_key')
  formData.append('id', id)

  return axios({
    method: 'post',
    url: demoScriptBaseUrl,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
export const PostPassLog = payload => api.post('/api/pass-log', payload)
export const SendBeacon = payload => navigator.sendBeacon(
     baseURL + '/api/pass-log',
     new Blob([JSON.stringify(payload)], {type: 'application/json'}),
    )
