<template>
  <header
    class="fixed top-0 left-0 z-10 bg-white flex"
    :style="`width: calc(100% - ${scrollWidth}px);`"
  >
    <div class="w-0 lg:w-16" />
    <div class="flex-1 flex pl-5 lg:pl-8 pr-5 py-5">
      <slot />
    </div>
  </header>
</template>


<script>
export default {
  computed: {
    scrollWidth() {
      return this.$store.getters.app.theme.scrollWidth
    },
  },
}
</script>
