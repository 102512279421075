<template>
  <div
    class="pass-step-context-menu-button"
    @click="onClick"
  >
    <icon-base
      :width="4"
      :height="20"
      class="cursor-pointer"
      view-box="0 0 4 20"
    >
      <icon-dots-menu :icon-name="$t('pass.stepMenu.title')" />
    </icon-base>
  </div>
</template>

<script>
import IconDotsMenu from '@/core/design-system/icon-base/icons/dots-menu.vue'

export default {
  components: {
    IconDotsMenu,
  },

  props: {
    step: {
      type: Object,
      default: null,
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', event)
      this.$store.dispatch('stepContextMenu/SET_STEP', this.step)
    },
  },
}
</script>
