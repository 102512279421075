// vue (https://vuejs.org/)
import Vue from 'vue'
import App from './App.vue'
// vue-router (https://router.vuejs.org/ru/)
import router from './router'
// vuex (https://vuex.vuejs.org/ru/)
import store from './store'


/**
 * Plugins
 */
// vue-i18n (https://github.com/kazupon/vue-i18n)
import i18n from './i18n'

/**
 * Config
 */
Vue.config.productionTip = false

import core from '@/core'
Vue.use(core)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
