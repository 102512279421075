<template>
  <div>
    <div
      v-if="variant === 'sm'"
      class="hs-table-compact collapse ring-gray-200 bg-white w-full"
      :class="{ 'collapse-open': collapseOpen }"
    >
      <div
        class="hs-table-compact__header collapse-title"
        @click="collapseOpen = !collapseOpen"
      >
        <div class="hs-table-compact__title">
          <span class="hs-table-compact__title-text block text-hs-header line-clamp-1">
            {{ placeholder }}
          </span>
        </div>
        <icon-base
          class="hs-table-compact__title-icon"
          :class="{ 'hs-table-compact__title-icon--opened': collapseOpen }"
          hide-title
        >
          <arrow-up />
        </icon-base>
      </div>
      <div class="collapse-content">
        <div class="hs-table-compact__controls">
          <div class="hs-table-compact__controls-left">
            <span> {{ currentRow }} &nbsp;/&nbsp; {{ rows.length }} </span>
          </div>
          <button
            class="hs-table-compact__button-add text-hs-info border-hs-info"
            @click="addRow"
          >
            <icon-base hide-title class="hs-table-compact__icon-add text-hs-info">
              <icon-plus />
            </icon-base>
          </button>
          <div class="hs-table-compact__controls-right">
            <button
              class="hs-table-compact__button-delete text-hs-info"
              @click="removeRow(currentRow - 1)"
            >
              <icon-base hide-title class="hs-table-compact__icon-delete">
                <icon-plus />
              </icon-base>
            </button>
          </div>
          <button
            class="hs-table-compact__controls-button hs-table-compact__controls-button--prev"
            @click="prev"
          >
            <icon-base
              class="hs-table-compact__controls-icon hs-table-compact__controls-icon--prev text-hs-info"
              hide-title
            >
              <arrow-up />
            </icon-base>
          </button>
          <button
            class="hs-table-compact__controls-button hs-table-compact__controls-button--next"
            @click="next"
          >
            <icon-base
              class="hs-table-compact__controls-icon hs-table-compact__controls-icon--next text-hs-info"
              hide-title
            >
              <arrow-up />
            </icon-base>
          </button>
        </div>
        <div class="hs-table-compact__table-wrapper">
          <table ref="table" :id="id" class="hs-table-compact__table">
            <transition mode="out-in" :name="chooseAnimation">
              <tr
                v-for="(row, i) in rows"
                v-if="i == currentRow - 1"
                :key="'tr' + i"
                class="hs-table-compact__table-row"
              >
                <td
                  v-for="(col, k) in row"
                  :key="'td' + k"
                  class="hs-table-compact__table-cell"
                >
                  <hs-input-material
                    v-if="options.columns[k].type === 'text'"
                    class="hs-table-compact__table-field"
                    :value="col"
                    :placeholder="options.columns[k].title"
                    @set-value="setValue(i, k, $event)"
                  />
                  <hs-number-material
                    v-else-if="options.columns[k].type === 'number'"
                    class="hs-table-compact__table-field"
                    :value="col"
                    :placeholder="options.columns[k].title"
                    :textNumeric="true"
                    @set-value="setValue(i, k, $event)"
                  />
                  <hs-select-material
                    v-else-if="options.columns[k].type === 'multilist'"
                    class="hs-table-compact__table-field"
                    :value="col"
                    :placeholder="options.columns[k].title"
                    :options="options.columns[k].values"
                    @set-value="setValue(i, k, $event)"
                  />
                </td>
              </tr>
            </transition>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="variant === 'md'"
      class="hs-table">
      <h3 class="hs-table__title text-hs-info">
        {{ placeholder }}
      </h3>
      <div
        class="hs-table__table-wrapper"
        :class="{'hs-table__table-wrapper--dragging': draggingTable}"
        >
        <table
          :id="id"
          class="hs-table__table"
          :class="{'hs-table__table--dragging': draggingTable}"
          ref="tableMd"
          @scroll = "scrollEndHandler"
          @mouseleave = "draggingHandlerEnd"
          @mousedown = "draggingHandlerStart"
          @mouseup = "draggingHandlerEnd"
          @mousemove = "draggingHandlerMove"
        >
          <transition-group name="table-list">
            <tbody
              v-for="(row, i) in rows"
              :key="'tr' + i"
              class="hs-table__table-row-body table-list-item"
            >
              <tr
                class="hs-table__table-row"
              >
                <td
                  v-for="(col, k) in row"
                  :key="'td' + k"
                  class="hs-table__table-cell"
                >
                  <el-tooltip
                    v-if="options.columns[k]?.type === 'text'"
                    effect="light"
                    :tabindex="-1"
                    :content="options.columns[k].title"
                    placement="top-start"
                    visible-arrow="false"
                    transition="none"
                  >
                    <hs-input
                      class="hs-table__table-field"
                      :value="col"
                      :placeholder="options.columns[k].title"
                      full-width
                      @set-value="setValue(i, k, $event)"
                    />
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="options.columns[k]?.type === 'number'"
                    effect="light"
                    :tabindex="-1"
                    :content="options.columns[k].title"
                    placement="top-start"
                    transition="none"
                  >
                    <hs-number
                      class="hs-table__table-field"
                      :value="col"
                      :placeholder="options.columns[k].title"
                      :textNumeric="true"
                      full-width
                      @set-value="setValue(i, k, $event)"
                    />
                  </el-tooltip>
                  <el-tooltip
                    v-else-if="options.columns[k]?.type === 'multilist'"
                    effect="light"
                    :tabindex="-1"
                    :content="options.columns[k].title"
                    placement="top-start"
                    transition="none"
                  >
                    <hs-select
                      class="hs-table__table-field"
                      :value="col"
                      :placeholder="options.columns[k].title"
                      :options="options.columns[k].values"
                      size="mini"
                      full-width
                      @set-value="setValue(i, k, $event)"
                    />
                  </el-tooltip>
                </td>
              </tr>
              <tr class="hs-table__table-row-delete">
                <button
                  class="hs-table__button-delete text-hs-info"
                  :class="{'hs-table__button-delete--less-cells': options.columns.length < colsInMdTable }"
                  @click="removeRow(i)"
                >
                  <el-tooltip
                    effect="light"
                    :tabindex="-1"
                    :content="tooltipsText.clearAllFields"
                    placement="right"
                  >
                    <icon-base hide-title class="hs-table__icon-delete">
                      <icon-plus />
                    </icon-base>
                  </el-tooltip>
                </button>
              </tr>
            </tbody>
          </transition-group>
        </table>
      </div>
      <div
        class="hs-table__controls"
        :class="{'hs-table__controls--has-controls': maxColShift}"
        >
        <button
          class="hs-table__button-add text-hs-info border-hs-info"
          @click="addRow"
        >
          <icon-base hide-title class="hs-table__icon-add text-hs-info">
            <icon-plus />
          </icon-base>
        </button>
        <div v-if="maxColShift" class="hs-table__slider-controls">
          <button
            class="hs-table__controls-button hs-table__controls-button--prev"
            @click="prevCol"
          >
            <icon-base
              class="hs-table__controls-icon hs-table__controls-icon--prev text-hs-info"
              hide-title
            >
              <arrow-up />
            </icon-base>
          </button>
          <div class="hs-table__controls-dots">
            <button
              class="hs-table__controls-dot-button"
              v-for="i in maxColShift + 1"
              :key="i"
              @click="translatedCols = i - 1"
            >
              <div
                class="hs-table__controls-dot"
                :class="{'hs-table__controls-dot--active': i == translatedCols + 1}"
                >
              </div>
            </button>
          </div>
          <button
            class="hs-table__controls-button hs-table__controls-button--next etext-hs-info"
            @click="nextCol"
          >
            <icon-base
              class="hs-table__controls-icon hs-table__controls-icon--next text-hs-info"
              hide-title
            >
              <arrow-up />
            </icon-base>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from '@/core/design-system/icon-base/icons/plus-table.vue'
import ArrowUp from '@/core/design-system/icon-base/icons/arrow-up.vue'
import HsInputMaterial from '@/core/design-system/form-elements/hs-input-material.vue'
import HsNumberMaterial from '@/core/design-system/form-elements/hs-number-material.vue'
import HsSelectMaterial from '@/core/design-system/form-elements/hs-select-material.vue'

export default {
  components: {
    IconPlus,
    ArrowUp,
    HsInputMaterial,
    HsNumberMaterial,
    HsSelectMaterial,
  },
  props: {
    value: {
      type: [Array, Object, String],
      default: () => ([]),
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: [Number, String],
      default: null,
    },
    options: {
      type: [Object, Array],
      default: () => ({}),
    },
    variant: {
      type: String,
      default: 'sm',
    },
    tooltipsText: {
      type: Object,
      default: () => ({clearAllFields: 'Clear all fields'}),
    },
  },
  data() {
    return {
      rows: [],
      collapseOpen: false,
      currentRow: this.variant == 'sm' ? 1 : 0,
      tabDirection: '',
      colsInMdTable: 3,
      colSize: 0,
      translatedCols: 0,
      draggingTable: false,
      startX: 0,
      scrollLeft: 0,
      draggingTimeout: null,
      scrollTimeout: null,
    }
  },
  computed: {
    chooseAnimation() {
      if (this.tabDirection === 'add-tab') {
        return 'table-add'
      } else if (this.tabDirection === 'remove-tab') {
        return 'table-remove'
      } else if (this.tabDirection === 'right') {
        return 'table'
      } else {
        return 'table-back'
      }
    },
    maxColShift() {
      if (!this.options.columns.length) return 0
      const diff = this.options.columns.length - this.colsInMdTable
      if (diff > 0) return diff
      return 0
    },
  },
  watch: {
    rows(value) {
      if (this.rows.length < this.currentRow) this.currentRow = this.rows.length
      this.$emit('set-value', { rows: value, columns: this.options.columns })
    },
    value(val) {
      this.$set(this, 'rows', val?.rows || [Array(this.options.columns.length).join('.').split('.')])
    },
    collapseOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.setMinHeight()
        })
      }
    },
    options() {
      this.translatedCols = 0
    },
    translatedCols(val) {
      const slideTranslateValue = this.colSize * val
      this.$refs.tableMd.scrollLeft = slideTranslateValue
    },
  },
  mounted() {
    this.rows = this.value.rows ? this.value.rows :
      [Array(this.options.columns.length).join('.').split('.')]
    this.$nextTick(() => {
      this.colSize = this.getCellSize()
    })
  },
  methods: {
    addRow() {
      const rowToInsert = Array(this.options.columns.length).join('.').split('.')
      if (this.currentRow !== 0) {
        this.rows.splice(this.currentRow, 0, rowToInsert)
        this.currentRow++
      } else {
        this.rows.push(rowToInsert)
      }
      this.tabDirection = 'add-tab'
    },
    removeRow(i) {
      if (this.rows.length <= 1 ) {
        this.clearFirstRow()
        return
      }
      if (i == 0 && this.rows.length > 1 ) {
        this.rows.splice(i, 1)
        this.tabDirection = 'remove-tab'
        return
      }
      this.rows.splice(i, 1)
      this.tabDirection = 'remove-tab'
      this.currentRow--
    },
    setValue(row, column, val) {
      this.$set(this.rows[row], column, val)
    },
    clearFirstRow() {
      const colsLength = this.rows[0].length
      for (let i = 0; i< colsLength; i++) {
        this.$set(this.rows[0], i, '')
      }
    },
    prev() {
      this.tabDirection = 'left'
      if (this.currentRow === 1) {
        this.currentRow = this.rows.length
      } else {
        this.currentRow--
      }
    },
    next() {
      this.tabDirection = 'right'
      if (this.currentRow === this.rows.length) {
        this.currentRow = 1
        this.showedRow = this.currentRow
      } else {
        this.currentRow++
      }
    },
    setMinHeight() {
      const table = this.$refs.table
      const height = table.clientHeight
      table.style.minHeight = height + 'px'
    },
    getCellSize() {
      const tableMd = this.$refs.tableMd
      if (tableMd) {
        const cell = tableMd.querySelector('.hs-table__table-cell')
        if (cell) {
          const style = window.getComputedStyle(cell)
          return cell.clientWidth + +style.marginRight.replace('px', '')
        }
        return 0
      }
      return 0
    },
    prevCol() {
      if (this.translatedCols <= 0) return
      this.translatedCols--
    },
    nextCol() {
      if (this.translatedCols >= this.maxColShift) return
      this.translatedCols++
    },
    detectActiveSlide() {
      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(() => {
        const scrollLeft = this.$refs.tableMd.scrollLeft
        const activeSlide = Math.round(scrollLeft/this.colSize)
        this.translatedCols = activeSlide
      }, 100)
    },
    scrollEndHandler() {
      if (this.draggingTable) return
      this.detectActiveSlide()
    },
    draggingHandlerStart(e) {
      this.draggingTimeout = setTimeout(() => {
        this.draggingTable = true
        this.startX = e.pageX
        this.scrollLeft = this.$refs.tableMd.scrollLeft
      }, 100)
    },
    draggingHandlerMove(e) {
      if (!this.draggingTable) return
      e.preventDefault()
      const slider = this.$refs.tableMd
      const x = e.pageX - slider.offsetLeft
      const walk = (x - this.startX)*3
      slider.scrollLeft = this.scrollLeft - walk
    },
    draggingHandlerEnd() {
      this.draggingTable = false
      setTimeout(() => {
        const scrollLeft = this.$refs.tableMd.scrollLeft
        const activeSlide = Math.round(scrollLeft/this.colSize)
        if (this.translatedCols == activeSlide) {
          const slideTranslateValue = this.colSize * activeSlide
          this.$refs.tableMd.scrollLeft = slideTranslateValue
        } else {
          this.detectActiveSlide()
        }
      }, 100)
      clearTimeout(this.draggingTimeout)
    },
  },
}
</script>
<style lang="sass" scoped>

.hs-table-compact
  border-radius: 10px
  border: 1px solid #EBEBEB
  max-width: 400px
  &__header
    display: flex
    position: relative
  &__title
    padding: 18px 20px
    padding-right: 40px
    width: 100%
    cursor: pointer
    -webkit-tap-highlight-color: transparent
  &__title-text
    font-size: 13px
    line-height: normal
    position: relative
    top: 1px
  &__title-icon
    display: block
    position: absolute
    color: #333E50
    right: 16px
    top: 18px
    width: 17px
    height: 17px
    transform: rotate(180deg)
    transform-origin: center
    transition: transform 0.2s
    cursor: pointer
    &--opened
      transform: rotate(0deg)
  &__controls
    display: flex
    justify-content: center
    align-items: center
    position: relative
    width: 100%
    padding-top: 5px
  &__controls-left
    text-align: right
    vertical-align: middle
    font-size: 13px
    user-select: none
    padding-right: 22px
    width: 30%
  &__button-add
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    box-sizing: border-box
    border-width: 2.2px
    border-radius: 6px
    flex-grow: 0
    flex-shrink: 0
  &__icon-add
    width: 16px
    height: 16px
  &__controls-right
    padding-left: 16px
    width: 30%
  &__button-delete
    width: 32px
    height: 32px
    display: flex
    justify-content: center
    align-items: center
  &__icon-delete
    width: 18px
    height: 18px
    transform: rotate(45deg)
    outline: none
  &__controls-button
    position: absolute
    &--prev
      left: 11px
    &--next
      right: 11px
  &__controls-icon
    width: 30px
    height: 30px
    &--prev
      transform: rotate(-90deg)
    &--next
      transform: rotate(90deg)
  &__table-wrapper
    padding: 20px 18px 0 18px
    width: 100%
  &__table
    position: relative
    width: 100%
  &__table-row
    display: flex
    flex-direction: column
    width: 100%
    flex-shrink: 0
  &__table-cell
    width: 100%
  &__table-field
    margin-bottom: 20px
    width: 100%

.hs-table
  padding-top: 14px
  padding-bottom: 25px
  max-width: 560px
  &__title
    font-size: 18px
    font-weight: 500
    margin-bottom: 2px
  &__table-wrapper
    position: relative
    transition: transform 0.2s
    &--dragging
      cursor: grabbing
      cursor: -webkit-grabbing
  &__table
    overflow: hidden
    display: flex
    flex-direction: column
    overflow-y: hidden
    overflow-x: scroll
    width: calc(100% - 40px)
    /* scroll-behavior: smooth */
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    &--dragging
      scroll-behavior: auto
  &__table-row-body
    display: flex
    align-items: flex-start
    margin-bottom: 10px
    &:last-of-type
      margin-bottom: 0px
  &__table-row
    display: flex
    align-items: flex-start
  &__table-row-delete
    width: 32px
  &__table-cell
    display: block
    flex-shrink: 0
    width: 160px
    padding: 2px
    margin-right: 20px
    transition: transform 0.3s
    &:last-of-type
      margin-right: 8px
  &__table-field
    display: block
    width: 100%
  &__button-delete
    position: absolute
    right: 0
    width: 32px
    height: 32px
    display: flex
    justify-content: center
    align-items: center
    &--less-cells
      position: initial
  &__icon-delete
    width: 15px
    height: 15px
    transform: rotate(45deg)
    outline: none
  &__controls
    display: flex
    margin-top: 5px
    &--has-controls
      align-items: center
      justify-content: center
  &__button-add
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    box-sizing: border-box
    border-width: 2.2px
    border-radius: 6px
    flex-grow: 0
    flex-shrink: 0
  &__icon-add
    width: 16px
    height: 16px
  &__slider-controls
    display: flex
    justify-content: space-between
    align-items: center
    margin-right: auto
    margin-left: auto
    gap: 12px
  &__controls-button
    &--prev
    &--next
  &__controls-icon
    width: 28px
    height: 28px
    &--prev
      transform: rotate(-90deg)
    &--next
      transform: rotate(90deg)
  &__controls-dots
    display: flex
    flex-wrap: wrap
    transition: all 0.5s
  &__controls-dot-button
    margin: 0
    border: none
    background-color: transparent
    display: flex
    justify-content: center
    align-items: center
    padding: 10px
  &__controls-dot
    width: 6px
    height: 6px
    border-radius: 999px
    background-color: #D9D9D9
    &--active
      background-color: #9AC8D3

::v-deep .hs-field-in-step .el-input .el-input__inner::placeholder /* monkey patching element ui select */
  color: rgba(146, 182, 190)!important
  font-size: 18px
  position: relative
  top: 2px

::v-deep .field-material .el-input .el-input__inner::placeholder /* monkey patching element ui select */
  font-size: 13px

</style>
<style>
.table-list-item {
  transition: all 0.3s;
  display: inline-block;
  margin-right: 10px;
}
.table-list-enter, .table-list-leave-to {
  opacity: 0;
  /* transform: translateX(30px); */
}
.table-list-leave-active {
  /* position: absolute; */
}
</style>
