<template>
  <div>
    <transition name="pass-step-context-menu-background">
      <div v-if="step" class="w-full h-full fixed bottom-0 left-0 pass-step-context-menu-background" @click="hideMenu" />
    </transition>
    <transition name="pass-step-context-menu">
      <div v-if="step" class="w-full fixed bottom-0 left-0 pass-step-context-menu">
        <div class="pass-step-context-menu-header">
          {{ header }}
        </div>
        <div class="pass-step-context-menu-item" @click="onCopy">
          {{ $t('pass.stepMenu.copyToClipboard') }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClipboardMixin from '@/core/infrastructure/clipboard/mixins/_clipboard-mixin.js'
import StepTextMixin from '../_step-text-mixin.js'

export default {
  mixins: [
    ClipboardMixin,
    StepTextMixin,
  ],

  props: {
    value: {
      type: Object||null,
      default: null,
    },
    header: {
      type: String||null,
      default: null,
    },
    items: {
      type: Array,
      default: () => { return [] },
    },
  },

  computed: {
    step() {
      return this.$store.getters.stepContextMenu.step
    },
  },

  methods: {
    async onCopy() {
      await this.copyToClipboard(this.convertJsonTextToPlainText(this.step.text))
      this.hideMenu()
    },

    hideMenu() {
      this.$store.dispatch('stepContextMenu/SET_STEP', null)
      this.$emit('input', null)
    },
  },
}
</script>
