import Scripts from '../core/list/views/scripts.vue'
import Pass from '../core/pass/views/pass.vue'
import Generic from '@/views/index.vue'

const routes = [
  {
    path: '/',
    component: Generic,
    children: [
      {
        path: '',
        name: 'Scripts',
        components: {core: Scripts},
      },
      {
        path: 'script/:id',
        name: 'Pass',
        components: {core: Pass},
      },
    ],
  },
]

if (process.env.NODE_ENV === 'development') {
  console.log('🧁 .env', process.env)
  routes.push({
    path: '/dev',
    name: 'dev',
    component: () => import('../core/dev/views/dev.vue'),
  })
}

// console.log('🚥 routes', routes)
export default routes
