import store from '../../../store/index.js'
import { integrationTypes } from '../../../integrations/store/integration-type.js'

export default {
    data: () => ({
        appFeatures: {
            FEATURE_AUTH_VK: 'auth_vk',
            FEATURE_AUTH_FB: 'auth_fb',
            FEATURE_DEMO_SCRIPT: 'demo_script',
        },
    }),
    computed: {
      conditions() {
        return {
          [this.appFeatures.FEATURE_DEMO_SCRIPT]: [
            conditionsTypes.IS_DEMO_INTEGRATION,
          ],
        }
      },
    },
    methods: {
        isFeatureToggled(feature) {
            if (!feature) return false
            const blackList = [this.appFeatures.FEATURE_AUTH_FB]
            if(blackList.includes(feature)) {
                return false
            }

            return Boolean(this.$store.getters.featureToggling.features[feature]) && checkCondition(this.conditions[feature])
        },

        initFeatureToggling() {
            if (this.$i18n.locale === 'ru')
                this.$store.dispatch('featureToggling/TURN_ON', this.appFeatures.FEATURE_AUTH_VK)
            this.$store.dispatch('featureToggling/TURN_ON', this.appFeatures.FEATURE_DEMO_SCRIPT)
        },
    },
}

// Conditions

const conditionsTypes = {
  IS_DEMO_INTEGRATION: 'isDemoIntegration',
}

const conditions = {
  [conditionsTypes.IS_DEMO_INTEGRATION]: () => {
    return store.getters.integrationType.integrationType === integrationTypes.DEMO
  },
}

const checkCondition = (conditionsList) => {
  if (!conditionsList || !conditionsList.length) return true
  return conditionsList.every(condition => conditions[condition]())
}
