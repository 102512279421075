const mixin = {
  methods: {
    convertJsonTextToPlainText(jsonText) {
      let text = ''
      const len = jsonText.length

      jsonText.forEach((val, idx) => {
        if (val.t !== undefined) {
          text += val.t
        }

        if (val['@c'] !== undefined) {
          text += ' __________ '
        }

        if (val._ !== undefined && val._.length > 0) {
          text += this.convertJsonTextToPlainText(val._)
        }

        if (val.e == 'p' && idx !== len - 1) {
          // eslint-disable-next-line
          text += "\n"
        }
      })

      return text
    },
  },
}

export default mixin