import Vue from 'vue'
export const eventBus = new Vue({
  methods: {
    interceptorCallback(obj, callback) {
      this.$emit('on-callback', obj, callback)
    },
  },
})

// EventBus (https://alligator.io/vuejs/global-event-bus/)
// import { eventBus } from '@/notification-bus.js'
