<template>
  <div class="hs-scripts-section">
    <scripts-header>
      <!-- left -->
      <div class="flex flex-col lg:flex-row flex-1">
        <div class="flex items-center flex-1">
          <icon-base
            width="26px"
            height="26px"
            view-box="0 0 48 48"
            :icon-name="$t('scripts.title')"
            class="hidden lg:inline mr-2 text-hs-brand-blue"
          >
            <icon-folder />
          </icon-base>
          <button class="lg:hidden w-8 h-8 mr-5 text-hs-secondary focus:outline-none" @click="$store.dispatch('app/TOGGLE_MAIN_SIDEBAR', true)">
            <icon-base :icon-name="$t('menu.open')">
              <icon-burger-menu />
            </icon-base>
          </button>
          <h2 class="hidden lg:inline mt-1 text-hs-header">
            {{ $t('scripts.title') }}
          </h2>
          <h2 class="lg:hidden">
            {{ currentTabTitle }}
          </h2>
        </div>
        <div
          class="scripts-search-field relative w-full lg:w-22 pl-11 pr-4 lg:pr-0 mt-5 lg:mt-0 flex items-center bg-gray-100 lg:bg-transparent rounded-xl lg:rounded-none"
          :class="{'lg:w-72': isSearchFieldFocus || isNotEmptySearchField, 'scripts-search-field-border': isSearchFieldFocus || isNotEmptySearchField }"
          style="transition: width var(--main-transition);"
        >
          <icon-base class="absolute left-2 text-hs-secondary cursor-pointer" @click.native="$refs.searchField.focus()">
            <icon-search :icon-name="$t('search')" />
          </icon-base>
          <input
            ref="searchField"
            v-model="searchValue"
            type="text"
            :placeholder="$t('search')"
            class="scripts-search text-sm flex-1 w-full bg-transparent py-4 lg:py-0 pl-0 pr-5 lg:pr-0 border-none lg:rounded-none focus:outline-none focus:ring-0"
            :class="{'lg:pr-8': isSearchFieldFocus || isNotEmptySearchField }"
            @focus="isSearchFieldFocus = true"
            @blur="isSearchFieldFocus = false"
          >
          <icon-base
            v-if="isNotEmptySearchField"
            :width="18"
            :height="18"
            class="absolute right-3 top-4 lg:top-1.5 text-hs-secondary cursor-pointer"
            @click.native="searchValue = ''"
          >
            <icon-x :icon-name="$t('clear')" />
          </icon-base>
        </div>
      </div>
      <!-- /left -->
      <!-- right -->
      <div
        class="hidden lg:flex items-center"
        style="padding-left: 1.25rem;"
        :style="`width: calc(486px - 2.75rem - ${scrollWidth}px);`"
      >
        <div class="flex gap-9 relative items-center text-hs-info">
          <tab
            v-for="(tab, idx) in desktopTabs"
            :key="idx"
            :style="{'padding': 0}"
            class="script-desktop-tab !px-0"
            :class="{'text-hs-brand-blue': desktopTabState.current === idx}"
            @toggle-tab="handleDesktopToggleTab($event, idx)"
          >
            <div class="flex items-center gap-2.5">
              <icon-base
                width="26"
                height="26"
                view-box="0 0 48 48"
                :icon-name="tab.label"
              >
                <component :is="`icon-${tab.icon}`" />
              </icon-base>
              <h2 class="mt-1 text-hs-header">
                {{ tab.label }}
              </h2>
            </div>
          </tab>
          <span class="absolute -bottom-4 left-1 right-1 z-10 h-1 bg-hs-danger" style="transition: all var(--main-transition);" :style="getDesktopTabStyle" />
        </div>
      </div>
      <!-- right -->
    </scripts-header>
    <div class="scripts-space-for-header" />
    <!-- body for lg -->
    <div class="hidden lg:flex py-5 pl-8 pr-5 min-h-full" style="min-height: calc(100vh - 72px)">
      <!-- body-left for lg -->
      <div class="flex-1">
        <div v-if="isWaiting" class="grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(180px,1fr));">
          <skeleton-script-preview-card
            v-for="i in randomCountForSkeleton1"
            :key="i"
            class="bg-gray-100 focus:outline-none"
          />
        </div>
        <div v-else class="grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(170px,1fr));">
          <script-preview-card
            v-for="script in scripts"
            :key="script.id"
            :to="{ name: integrationType + 'Pass', params: { id: script.id } }"
            :disabled="script.disabled"
            class="bg-gray-100 box-border focus:outline-none focus:ring-2 focus:ring-hs-primary focus:ring-opacity-50"
          >
            {{ script.name }}
          </script-preview-card>
        </div>
        <p v-if="scripts.length === 0 && !isWaiting" class="text-sm opacity-50">
          {{ $t('scripts.empty') }}..
        </p>
      </div>
      <!-- /body-left for lg -->
      <!-- body-right for lg -->
      <div
        class="overflow-hidden"
        style="padding-left: 1.25rem;"
        :style="`width: calc(486px - 2.75rem - ${scrollWidth * hasScrollbar}px);`"
      >
        <div class="relative">
          <transition-group :name="chooseDirectionDesktopTab" tag="div">
            <div v-show="desktopTabState.current === 0" key="starred" class="fragment w-full">
              <div v-if="isWaiting" class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(180px,1fr));">
                <skeleton-script-preview-card
                  v-for="i in randomCountForSkeleton2"
                  :key="i"
                  class="bg-blue-50 focus:outline-none"
                />
              </div>
              <div v-else class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(180px,1fr));">
                <script-preview-card
                  v-for="script in starredScripts"
                  :key="script.id"
                  :to="{ name: integrationType + 'Pass', params: { id: script.id } }"
                  :disabled="script.disabled"
                  class="bg-blue-50 focus:outline-none focus:ring-2 focus:ring-hs-primary focus:ring-opacity-50"
                >
                  {{ script.name }}
                </script-preview-card>
              </div>
              <p v-if="starredScripts.length === 0 && !isWaiting" class="text-sm opacity-50">
                {{ $t('scripts.emptyStarred') }}..
              </p>
            </div>
            <div v-show="desktopTabState.current === 1" key="recent" class="fragment w-full">
              <div v-if="isWaiting" class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(180px,1fr));">
                <skeleton-script-preview-card
                  v-for="i in randomCountForSkeleton2"
                  :key="i"
                  class="bg-blue-50 focus:outline-none"
                />
              </div>
              <div v-else class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(180px,1fr));">
                <script-preview-card
                  v-for="script in openedScripts"
                  :key="script.id"
                  :to="{ name: integrationType + 'Pass', params: { id: script.id } }"
                  class="bg-blue-50 focus:outline-none focus:ring-2 focus:ring-hs-primary focus:ring-opacity-50"
                >
                  {{ script.name }}
                </script-preview-card>
              </div>
              <p v-if="openedScripts.length === 0 && !isWaiting" class="text-sm opacity-50">
                {{ $t('scripts.emptyRecent') }}..
              </p>
            </div>
          </transition-group>
        </div>
      </div>
      <!-- /body-right for lg -->
    </div>
    <!-- /body for lg -->
    <!-- body for mobi -->
    <div class="lg:hidden w-full pt-1 pb-28">
      <transition-group :name="chooseDirectionMobileTab" tag="div">
        <div v-show="mobileTabState.current === 0" key="scripts" class="fragment w-full px-5 pb-5">
          <div v-if="isWaiting" class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(130px,1fr));">
            <skeleton-script-preview-card
              v-for="i in randomCountForSkeleton1"
              :key="i"
              class="bg-gray-100 focus:outline-none"
            />
          </div>
          <div v-else class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(130px,1fr));">
            <script-preview-card
              v-for="script in scripts"
              :key="script.id"
              :to="{ name: integrationType + 'Pass', params: { id: script.id } }"
              :disabled="script.disabled"
              class="bg-gray-100 focus:outline-none focus:ring-2 focus:ring-hs-primary focus:ring-opacity-50"
            >
              {{ script.name }}
            </script-preview-card>
          </div>
          <p v-if="scripts.length === 0 && !isWaiting" class="text-sm opacity-50">
            {{ $t('scripts.empty') }}..
          </p>
        </div>
        <div v-show="mobileTabState.current === 1" key="starred" class="fragment w-full px-5 pb-5">
          <div v-if="isWaiting" class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(130px,1fr));">
            <skeleton-script-preview-card
              v-for="i in randomCountForSkeleton1"
              :key="i"
              class="bg-blue-50 focus:outline-none"
            />
          </div>
          <div v-else class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(130px,1fr));">
            <script-preview-card
              v-for="script in starredScripts"
              :key="script.id"
              :to="{ name: integrationType + 'Pass', params: { id: script.id } }"
              :disabled="script.disabled"
              class="bg-blue-50 focus:outline-none focus:ring-2 focus:ring-hs-primary focus:ring-opacity-50"
            >
              {{ script.name }}
            </script-preview-card>
          </div>
          <p v-if="starredScripts.length === 0 && !isWaiting" class="text-sm opacity-50">
            {{ $t('scripts.emptyStarred') }}..
          </p>
        </div>
        <div v-show="mobileTabState.current === 2" key="recent" class="fragment w-full px-5 pb-5">
          <div v-if="isWaiting" class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(130px,1fr));">
            <skeleton-script-preview-card
              v-for="i in randomCountForSkeleton2"
              :key="i"
              class="bg-blue-50 focus:outline-none"
            />
          </div>
          <div v-else class="w-full grid gap-5" style="grid-template-columns: repeat(auto-fill,minmax(130px,1fr));">
            <script-preview-card
              v-for="script in openedScripts"
              :key="script.id"
              :to="{ name: integrationType + 'Pass', params: { id: script.id } }"
              class="bg-blue-50 focus:outline-none focus:ring-2 focus:ring-hs-primary focus:ring-opacity-50"
            >
              {{ script.name }}
            </script-preview-card>
          </div>
          <p v-if="openedScripts.length === 0 && !isWaiting" class="text-sm opacity-50">
            {{ $t('scripts.emptyRecent') }}..
          </p>
        </div>
      </transition-group>
    </div>
    <!-- /body for mobi -->
    <!-- mobile-nav -->
    <div
      class="lg:hidden fixed bottom-0 left-0 w-full h-14 px-5 bg-hs-toolbar"
      :style="`width: calc(100% - ${scrollWidth}px);`"
    >
      <div class="relative w-64 mx-auto h-full flex items-center justify-between text-hs-info">
        <tab
          v-for="(tab, idx) in mobileTabs"
          :key="idx"
          class="script-mobile-tab"
          :class="{'text-hs-brand-blue': mobileTabState.current === idx}"
          @toggle-tab="handleMobileToggleTab($event, idx)"
        >
          <icon-base
            :icon-name="tab.label"
            width="26px"
            height="26px"
            view-box="0 0 48 48"
          >
            <component :is="`icon-${tab.icon}`" />
          </icon-base>
        </tab>
        <span class="absolute bottom-0 z-10 h-1 bg-hs-danger" style="transition: all var(--main-transition);" :style="getMobileTabStyle" />
      </div>
    </div>
    <!-- /mobile-nav -->
  </div>
</template>


<script>
import { getCurrentInstance } from 'vue'
import { random } from '@/core/infrastructure/helpers/helpers.js'

// tab-panel
import tabPanelMixin from '@/core/components/tab-panel/_tab-panel-mixin.js'
import Tab from '@/core/components/tab-panel/tab.vue'

// tab-panel composition api hook
import  { useTabPanel } from '@/core/components/tab-panel/useTabPanel.js'

// ui
import ScriptsHeader from '@/core/list/components/headers/scripts-header.vue'
import ScriptPreviewCard from '@/core/list/components/script-preview-card/script-preview-card.vue'
import SkeletonScriptPreviewCard from '@/core/list/components/skeletons/skeleton-script-preview-card.vue'

// icons
import IconFolder from '@/core/design-system/icon-base/icons/folder.vue'
import IconClock from '@/core/design-system/icon-base/icons/clock.vue'
import IconBurgerMenu from '@/core/design-system/icon-base/icons/burger-menu.vue'
import IconSearch from '@/core/design-system/icon-base/icons/search.vue'
import IconX from '@/core/design-system/icon-base/icons/x.vue'
import IconStar from '@/core/design-system/icon-base/icons/star.vue'

export default {
  components: {
    ScriptsHeader,
    ScriptPreviewCard,
    SkeletonScriptPreviewCard,
    IconFolder,
    IconClock,
    IconBurgerMenu,
    IconSearch,
    IconX,
    IconStar,
    Tab,
  },
  mixins: [ tabPanelMixin ],
  props: {
    integrationType: {
      type: String,
      default: '',
    },
  },
  setup() {
    const app = getCurrentInstance()
    const { tabPanelState: desktopTabState,
      toggleTab: desktopToggleTab,
      getTabIndicatorStyle: getDesktopTabStyle,
      chooseDirectionForTab: chooseDirectionDesktopTab } = useTabPanel()

    const { tabPanelState: mobileTabState,
      toggleTab: mobileToggleTab,
      getTabIndicatorStyle: getMobileTabStyle,
      chooseDirectionForTab: chooseDirectionMobileTab } = useTabPanel()

    const handleDesktopToggleTab = (event, idx) => {
      app.proxy.$store.dispatch('app/SET_CHOOSEN_TAB', idx)
      desktopToggleTab(event, idx)
    }

    const handleMobileToggleTab = (event, idx) => {
      app.proxy.$store.dispatch('app/SET_CHOOSEN_TAB', idx)
      mobileToggleTab(event, idx)
    }

    return {
      desktopTabState,
      handleDesktopToggleTab,
      getDesktopTabStyle,
      chooseDirectionDesktopTab,
      mobileTabState,
      handleMobileToggleTab,
      getMobileTabStyle,
      chooseDirectionMobileTab,
    }
  },
  data: function() { return {
    // search input
    isSearchFieldFocus: false,
    searchValue: '',
    // tabs for mobile nav
    // TODO: change to i18n
    mobileTabs: [
      { label: this.$i18n.t('scripts.title'), icon: 'folder' },
      { label: this.$i18n.t('starred'), icon: 'star' },
      { label: this.$i18n.t('recent'), icon: 'clock' },
    ],
    // tabs for desktop
    desktopTabs: [
      { label: this.$i18n.t('starred'), icon: 'star' },
      { label: this.$i18n.t('recent'), icon: 'clock' },
    ],
    // random numbers for skeleton items
    randomCountForSkeleton1: random(8, 30),
    randomCountForSkeleton2: random(3, 6),
    hasScrollbar: 1,
  }},
  computed: {
    isOnline() {
      // TODO: change this inheriting
      return this.$parent.$parent.isOnline
    },
    currentTabTitle() {
      return this.mobileTabs[this.mobileTabState.current].label
    },
    isNotEmptySearchField() {
      return this.searchValue.length > 0
    },
    scrollWidth() {
      return this.$store.getters.app.theme.scrollWidth
    },
    isWaiting() {
      return this.$store.getters.app.isWaiting
    },
    choosenTab() {
      return this.$store.getters.app.choosenTab
    },
    scripts() {
      const scripts = this.$store.getters.scripts.scripts
      if (this.isOnline === true) {
        return scripts
          .filter(script => script.name.toLowerCase().includes(this.searchValue.toLowerCase()))
      } else {
        return scripts.map(e => ({
          ...e,
          disabled: !this.$store.getters.scripts.openedScripts.some(script => script.id === e.id),
        })).filter(script => script.name.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
    },
    openedScripts() {
      return this.$store.getters.scripts.openedScripts
        .filter(script => script.name.toLowerCase().includes(this.searchValue.toLowerCase()))
        .sort((a, b) =>  b._last_view - a._last_view)
    },
    starredScripts() {
      const starredScripts = this.$store.getters.starredScripts.starredScripts
      return this.scripts.filter((script) => starredScripts.includes(script.id))
        .filter(script => script.name.toLowerCase().includes(this.searchValue.toLowerCase()))
        .sort((a, b) =>  b._last_view - a._last_view)
    },
    scriptWaitingToUpdate() {
      return this.$store.getters.cache.trigger === 'update'
    },
    scriptWaitingToRemove() {
      return this.$store.getters.cache.trigger === 'remove'
    },
  },
  created() {
    this.$root.$on('fixCurrentTabActiveInScripts', this.fixCurrentTabActiveInScripts)
  },
  mounted() {
    this.fixCurrentTabActiveInScripts()
    this.$store.dispatch('cache/CLEAR_CURRENT_WORKSPACE')
    this.$store.dispatch('starredScripts/GET_STARRED_SCRIPTS')
    if (this.scriptWaitingToRemove === true) {
      this.$store.dispatch('cache/REMOVE_SCRIPT', { id: this.$store.getters.cache.triggerScriptId })
    }
    if (this.scriptWaitingToUpdate === true) {
      this.$store.dispatch('cache/UPDATE_SCRIPT', { id: this.$store.getters.cache.triggerScriptId, vm: this })
    }
    const element = document.querySelector('.hs-scripts-section')
    new ResizeObserver(() => {
      element.scrollHeight > window.innerHeight ? this.hasScrollbar = 1 : this.hasScrollbar = 0
    }).observe(element)
  },
  updated() {
    const element = document.querySelector('.hs-scripts-section')
    element.scrollHeight > window.innerHeight ? this.hasScrollbar = 1 : this.hasScrollbar = 0
  },
  methods: {
    fixCurrentTabActiveInScripts() {
      this.$nextTick(() => {
        const desktopTab = document.querySelectorAll('.script-desktop-tab')[this.choosenTab]
        if (desktopTab) {
          desktopTab.click()
        }
        const mobileTab = document.querySelectorAll('.script-mobile-tab')[this.choosenTab]
        if (mobileTab) {
          mobileTab.click()
        }
      })
    },
  },
}
</script>
