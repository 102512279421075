<template>
  <button class="flex items-center justify-center w-9 h-9 rounded-full bg-hs-light-gray" @click="handleClick">
    <icon-base
      :width="4"
      :height="20"
      class="cursor-pointer"
      view-box="0 0 4 20"
    >
      <icon-dots-menu :icon-name="$t('pass.scriptMenu.title')" />
    </icon-base>
  </button>
</template>

<script>
import IconDotsMenu from '@/core/design-system/icon-base/icons/dots-menu.vue'

export default {
  components: {
    IconDotsMenu,
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event)
    },
  },
}
</script>
