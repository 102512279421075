<template>
  <div>
    <div v-if="Object.values(currentFields).length > 0" class="grid gap-5">
      <div
        v-for="f in currentFields"
        :key="f.id"
        class="grid w-full"
      >
        <hs-input-material
          v-if="f.type === 'text'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :autocomplete="f.options.autocomplete"
          @set-value="setValue($event, f.id)"
        />
        <hs-textarea-material
          v-else-if="f.type === 'textarea'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          @set-value="setValue($event, f.id)"
        />
        <hs-table
          v-else-if="f.type === 'table'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :options="f.options"
          @set-value="setValue($event, f.id)"
        />
        <hs-checkbox-material
          v-else-if="f.type === 'checkbox'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          @set-value="setValue($event, f.id)"
        />
        <hs-number-material
          v-else-if="f.type === 'number'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :options="f.options"
          :textNumeric="true"
          @set-value="setValue($event, f.id)"
        />
        <hs-datetime-picker-material
          v-else-if="f.type === 'date'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          @set-value="setValue($event, f.id)"
        />
        <hs-select-material
          v-else-if="f.type === 'multilist'"
          :id="f.id"
          :value="f.value"
          :placeholder="f.name"
          :options="getThisFieldOptions(f.id)"
          @set-value="setValue($event, f.id)"
        />
      </div>
    </div>
    <div v-else class="opacity-50">
      <p class="font-normal text-sm">
        {{ $t('pass.data.empty') }}..
      </p>
    </div>
  </div>
</template>


<script>
import FieldsInScriptMixin from '../_fields-in-script-mixin.js'
import HsInputMaterial from '@/core/design-system/form-elements/hs-input-material.vue'
import HsTextareaMaterial from '@/core/design-system/form-elements/hs-textarea-material.vue'
import HsCheckboxMaterial from '@/core/design-system/form-elements/hs-checkbox-material.vue'
import HsNumberMaterial from '@/core/design-system/form-elements/hs-number-material.vue'
import HsDatetimePickerMaterial from '@/core/design-system/form-elements/hs-datetime-picker-material.vue'
import HsSelectMaterial from '@/core/design-system/form-elements/hs-select-material.vue'


export default {
  components: {
    HsInputMaterial,
    HsTextareaMaterial,
    HsCheckboxMaterial,
    HsNumberMaterial,
    HsDatetimePickerMaterial,
    HsSelectMaterial,
  },
  mixins: [FieldsInScriptMixin],
  computed: {
    uid() {
      return this.$parent.uid
    },
    currentFields() {
      return this.$parent.$props.currentFields
    },
  },
}
</script>
