<template>
  <div>
    <transition name="pass-script-context-menu-background">
      <div
        v-if="openedScriptMenu"
        class="w-full h-full fixed bottom-0 left-0 pass-step-context-menu-background" 
        @click="closeMenu"
      />
    </transition>
    <transition name="pass-script-context-menu">
      <div v-if="openedScriptMenu" class="pass-script-context-menu w-full fixed bottom-0 left-0 ">
        <div class="pass-script-context-menu-header">
          {{ header }}
        </div>
        <div class="pass-script-context-menu-item" @click="handleClick">
          <div v-if="isStarredScriptDelayed" class="w-full flex gap-3.5" @click="handleClick">
            <icon-base
              height="16"
              width="18"
              view-box="0 0 55 48"
              :icon-name="$t('pass.removeFromStarred')"
            >
              <icon-star-minus />
            </icon-base>
            {{ $t('pass.removeFromStarred') }}
          </div>
          <div v-else class="w-full flex gap-4" @click="handleClick">
            <icon-base
              width="16"
              height="16"
              view-box="0 0 48 48"
              :icon-name="$t('pass.addToStarred')"
            >
              <icon-star />
            </icon-base>
            {{ $t('pass.addToStarred') }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconStar from '@/core/design-system/icon-base/icons/star.vue'
import IconStarMinus from '@/core/design-system/icon-base/icons/star-minus.vue'

export default {
  components: {
    IconStar,
    IconStarMinus,
  },
  props: {
    isStarredScript: {
      type: Boolean,
      default: false,
    },
    openedScriptMenu: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String||null,
      default: null,
    },
  },
  data: () => ({
    isStarredScriptDelayed: null,
  }),
  watch: {
    isStarredScript() {
      setTimeout(() => this.isStarredScriptDelayed = this.isStarredScript, 300)
    },
  },
  mounted() {
    this.isStarredScriptDelayed = this.isStarredScript
  },
  methods: {
    closeMenu(event) {
      this.$emit('closeMenu', event)
    },
    handleClick(event) {
      this.$emit('toggleStarred', event)
    },
  },
}
</script>
