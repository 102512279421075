<template>
  <button
    ref="tab"
    class="h-full px-5 focus:outline-none"
    style="transition: color .3s;"
    @click="toggleTab"
  >
    <slot />
  </button>
</template>


<script>
export default {
  methods: {
    toggleTab() {
      const payload = {
        indicator_width: this.$refs.tab.offsetWidth,
        indicator_pos: this.$refs.tab.offsetLeft,
      }
      this.$emit('toggle-tab', payload)
    },
  },
}
</script>
