<template>
  <div>
    <div v-if="currentQuickTransitions.length === 0">
      <p class="font-normal text-sm">
        {{ $t('pass.quickTransitions.empty') }}..
      </p>
    </div>
    <div v-else>
      <hs-input-search
        id="quickTransitionsSearch"
        :value="searchValue"
        :placeholder="$t('search')"
        class="mb-2 lg:mb-5"
        @set-value="setValue($event)"
      />
      <div
        v-for="(item, idx) in currentQuickTransitionsWithSearch"
        :key="idx"
        class="notes-item"
      >
        <vs-collapse>
          <vs-collapse-item
            :open="thisCategoryIsOpen(idx)"
            :on-callback="() => { toggleCategory(idx) }"
          >
            <span
              slot="header"
              class="select-none font-medium text-base"
            >
              {{ item.n }}
            </span>
            <div
              v-for="i in item.s"
              :key="i.id"
              class="qt-item"
              :class="{'qt-is-active': currentStepDataId === i.target}"
            >
              <div class="qt-bg">
                <p
                  class="line-clamp-1 font-normal cursor-pointer text-base select-none"
                  @click="toggleQuickTransition(i.target)"
                >
                  {{ i.label }}
                </p>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>


<script>
import HsInputSearch from '@/core/design-system/form-elements/hs-input-search.vue'
import VsCollapse from '@/core/components/vs-collapse/vs-collapse.vue'
import VsCollapseItem from '@/core/components/vs-collapse/vs-collapse-item.vue'

export default {
  components: {
    HsInputSearch,
    VsCollapse,
    VsCollapseItem,
  },
  data: () => ({
    searchValue: '',
  }),
  computed: {
    currentQuickTransitions() {
      return this.$parent.$props.currentQuickTransitions
    },
    currentQuickTransitionsWithSearch() {
      return this.currentQuickTransitions.map(e => {
        const items = e.s.filter(step => step.label.toLowerCase().includes(this.searchValue.toLowerCase()))
        return {
          n: e.n,
          s: items,
          _flag: items.length > 0,
        }
      }).filter(e => e._flag)
    },
    currentStepDataId() {
      return this.$parent.$parent.currentStepData.step
        ? this.$parent.$parent.currentStepData.step.id
        : 'start'
    },
    openQuickTransitionsCategories() {
      return this.$parent.qt.openQuickTransitionsCategories
    },
  },
  methods: {
    setValue(val) {
      this.searchValue = val
    },
    toggleQuickTransition(eventId) {
      /**
       * eventId - step id
       * null - link id (you don't need it for a quick transition)
       * true - flag that the button is a quick transition
       */
      this.$parent.$parent.send(eventId, null, true)
      this.$store.dispatch('app/TOGGLE_TOOLBAR', false)
    },
    toggleCategory({ category_id }){
      this.$parent.toggleQuickTransitionCategory(category_id)
    },
    thisCategoryIsOpen({ category_id }) {
      return !this.openQuickTransitionsCategories.some(el => el === category_id)
    },
  },
}
</script>
