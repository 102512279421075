<template>
  <span class="hs-field-in-step">
    <textarea
      :id="id"
      :value="value"
      :placeholder="placeholder"
      @input="setValue($event)"
    />
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'

export default {
  mixins: [FieldBaseMixin],
}
</script>
