<template>
  <script-preview-card class="skeleton">
    <div class="skeleton-anime" :style="`width: ${width}%;`">
      <p class="opacity-0">
        anime
      </p>
    </div>
  </script-preview-card>
</template>


<script>
import { random } from '@/core/infrastructure/helpers/helpers.js'
import ScriptPreviewCard from '@/core/list/components/script-preview-card/script-preview-card.vue'


export default {
  components: {
    ScriptPreviewCard,
  },
  data() {
    return {
      width: random(60, 100),
    }
  },
}
</script>
