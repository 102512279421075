<script>
import Pass from '../../../core/pass/views/pass.vue'
import LoadingScript from '../../../core/application/components/preloaders/loading-script.vue'
import { integrationTypes } from '../../store/integration-type.js'

export default {
  name: 'EntryPoint',
  components: {
    Pass: Pass,
    LoadingScript: LoadingScript,
  },
  data() {
    return {
      scriptId: null,
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.app.loadingDemoScript
    },
  },
  created() {
    this.scriptId = this.$route.params.id
  },
  mounted() {
    this.initFeatureToggling()
    this.$store.dispatch('integrationType/SET_INTEGRATION_TYPE', { type: integrationTypes.DEMO})
    this.$store.dispatch('demoScript/GET_DEMO_SCRIPT', {id: this.scriptId})
  },
}
</script>

<template>
  <div class="fragment">
    <transition-group name="fade" tag="div">
      <LoadingScript v-show="isLoading" key="preload" />
      <main v-if="!isLoading" key="main-demo" class="relative w-full h-screen flex bg-white">
        <Pass class="w-full" integration-type="demo" />
      </main>
    </transition-group>
  </div>
</template>
