export const STATIC_FIELD_TYPES = {
  'profile_firstname': 'first_name',
  'profile_lastname': 'last_name',
  'profile_email': 'username',
  'profile_link': 'ip',
}

export const OUTCOME_TYPES = [
  'unknown',
  'unexpected_answer',
  'forced_interruption',
  'ok',
]
