<template>
  <div class="skeleton">
    <div class="skeleton-anime mb-2" style="width: 100%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <div class="skeleton-anime mb-2" style="width: 96%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <div class="skeleton-anime mb-2" style="width: 98%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <div class="skeleton-anime mb-2" style="width: 43%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <div class="mb-2">
      <p class="opacity-0">
        br
      </p>
    </div>
    <div class="skeleton-anime mb-2" style="width: 86%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <div class="skeleton-anime mb-2" style="width: 90%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <div class="skeleton-anime mb-2" style="width: 71%;">
      <p class="opacity-0">
        lorem text
      </p>
    </div>
    <!-- Answer options -->
    <div class="mt-5">
      <pass-variant-picker
        v-for="i in 4"
        :key="i"
        :class="`${getRandomStatus(i)} skeleton-variant`"
        without-anime
        @click="() => {}"
      >
        <div class="skeleton-anime">
          <p class="opacity-0">
            {{ i }}
          </p>
        </div>
      </pass-variant-picker>
    </div>
    <!-- /Answer options -->
    <div class="mt-5">
      <skeleton-hs-main-button
        class="lg:w-72"
      >
        fake button
      </skeleton-hs-main-button>
    </div>
  </div>
</template>


<script>
import { random } from '@/core/infrastructure/helpers/helpers.js'
import PassVariantPicker from '@/core/pass/components/pass-elements/pass-body/pass-variant-picker.vue'
import SkeletonHsMainButton from '@/core/design-system/skeletons/skeleton-hs-main-button.vue'

export default {
  components: {
    PassVariantPicker,
    SkeletonHsMainButton,
  },
  data() {
    return {
      randomNumber: random(4, 9),
    }
  },
  methods: {
    getRandomStatus(num) {
      return parseInt(this.randomNumber / 2, 10) > num
        ? 'positive'
        : parseInt(this.randomNumber / 2, 10) === num
          ? ''
          : 'negative'
    },
    getRandomWidth() {
      return random(60, 100)
    },
  },
}
</script>
