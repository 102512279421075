const mixin = {
  data: () => ({
    tabPanelState: {
      current: 0,
      indicator_width: 0,
      indicator_pos: 0,
      forwards: true,
    },
  }),
  computed: {
    getTabIndicatorStyle() {
      return {
        left: this.tabPanelState.indicator_pos + 'px',
        width: this.tabPanelState.indicator_width + 'px',
      }
    },
    chooseDirectionForTab() {
      return this.tabPanelState.forwards ? 'tab' : 'tab-back'
    },
  },
  methods: {
    toggleTab(tab, index) {
      this.tabPanelState.indicator_width = tab.indicator_width
      this.tabPanelState.indicator_pos = tab.indicator_pos
      this.changeTab(index)
    },
    changeTab(index) {
      const prev = this.tabPanelState.current
      if (prev > index) {
        this.tabPanelState.forwards = false
      } else {
        this.tabPanelState.forwards = true
      }
      this.tabPanelState.current = index
    },
  },
}

export default mixin
