<script>
export default {
  name: 'IndexView',
  data() {
    return {
      context: {},
    }
  },

}
</script>

<template>
  <div>
    <hyperscript-pass-core />
  </div>
</template>

<style scoped>

</style>
