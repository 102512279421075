<template>
  <div
    class="pass-toolbar bg-white z-30 fixed inset-0 lg:inset-auto lg:relative lg:left-auto w-full lg:w-486 max-h-screen lg:bg-hs-toolbar"
    :class="isOpen ? 'left-0' : 'left-full'"
  >
    <!-- title -->
    <div class="flex lg:hidden items-center pl-5 lg:pl-8 pr-5 py-5">
      <div class="flex items-center flex-1">
        <button class="mr-5 focus:outline-none" @click="toggleBtnClose">
          <icon-base :icon-name="$t('back')">
            <icon-arrow-left />
          </icon-base>
        </button>
        <h2
          v-if="customTitle"
          class="line-clamp-1"
        >
          {{ notes.currentNote && notes.currentNote.title }}
        </h2>
        <h2
          v-else
          class="line-clamp-1"
        >
          {{ currentTitle }}
        </h2>
      </div>
    </div>
    <!-- /title -->

    <!-- body -->
    <div class="relative fix-toolbar-container-tab max-w-full overflow-x-hidden">
      <transition :name="chooseDirectionForTab">
        <div v-if="isWaiting" class="fix-toolbar-view-tab w-full py-5 px-5 lg:px-12">
          <skeleton-toolbar />
        </div>
        <component :is="selected" v-else class="fix-toolbar-view-tab w-full pt-5 pb-20 lg:pb-5 px-5 lg:px-12" />
      </transition>
    </div>
    <!-- /body -->

    <!-- tab-panel -->
    <div
      class="pass-toolbar-tab-panel absolute md:relative bottom-0 left-0 w-full h-14 lg:mt-2 bg-hs-toolbar"
    >
      <div class="relative w-64 h-full mx-auto lg:mx-12 flex items-center justify-between text-hs-info">
        <tab
          v-for="(tab, idx) in tabs"
          :key="idx"
          class="toolbar-tab"
          :class="{'text-hs-brand-blue': tabPanelState.current === idx}"
          @toggle-tab="toggleTab($event, idx)"
        >
          <icon-base :icon-name="tab.label">
            <component :is="`icon-${tab.icon}`" />
          </icon-base>
        </tab>
        <span class="absolute bottom-0 z-10 h-1 bg-hs-danger" style="transition: all var(--main-transition);" :style="getTabIndicatorStyle" />
      </div>
    </div>
    <!-- /tab-panel -->
  </div>
</template>


<script>
// tab-panel
import tabPanelMixin from '@/core/components/tab-panel/_tab-panel-mixin.js'
import Tab from '@/core/components/tab-panel/tab.vue'

// tools elements
import QuickTransitions from './_quick-transitions.vue'
import Notes from './_notes.vue'
import Fields from './_fields.vue'

// icons
import IconQuick from '@/core/design-system/icon-base/icons/quick.vue'
import IconMessage from '@/core/design-system/icon-base/icons/message.vue'
import IconPaste from '@/core/design-system/icon-base/icons/paste.vue'
import IconArrowLeft from '@/core/design-system/icon-base/icons/arrow-left.vue'

// ui
import SkeletonToolbar from '@/core/components/skeletons/skeleton-toolbar.vue'

export default {
  components: {
    Tab,
    QuickTransitions,
    Notes,
    Fields,
    IconQuick,
    IconMessage,
    IconPaste,
    IconArrowLeft,
    SkeletonToolbar,
  },
  mixins: [tabPanelMixin],
  props: {
    currentQuickTransitions: {
      type: Array,
      default: () => ([]),
    },
    currentViewNotes: {
      type: [Object, Array],
      default: () => ({}),
    },
    currentFields: {
      type: [Object, Array],
      default: () => ({}),
    },
    isWaiting: {
      type: Boolean,
      default: false,
    },
  },
  data: function() { return {
    tabs: [
      { name: 'quick-transitions', label: this.$i18n.t('pass.quickTransitions.title'), icon: 'quick' },
      { name: 'notes', label: this.$i18n.t('pass.notes.title'), icon: 'message' },
      { name: 'fields', label: this.$i18n.t('pass.data.title'), icon: 'paste' },
    ],
    notes: {
      currentNote: null,
      openedNoteCategories: [],
    },
    qt: { // quick transitions
      openQuickTransitionsCategories: [],
    },
  }},
  computed: {
    uid() {
      return this.$parent.currentScript._uid
    },
    isOpen() {
      return this.$store.getters.app.toolbar.isOpen
    },
    scrollWidth() {
      return this.$store.getters.app.theme.scrollWidth
    },
    selected() {
      return this.tabs[this.tabPanelState.current].name
    },
    currentTitle() {
      return this.tabs[this.tabPanelState.current].label
    },
    customTitle() {
      return this.tabs[this.tabPanelState.current].name === 'notes' && this.notes.currentNote !== null
    },
  },
  created() {
    this.$root.$on('fixCurrentTabActiveInPassToolbar', this.fixCurrentTabActiveInPassToolbar)
  },
  mounted() {
    this.fixCurrentTabActiveInPassToolbar()
  },
  methods: {
    fixCurrentTabActiveInPassToolbar() {
      this.$nextTick(() => {
        document.querySelector('.toolbar-tab')?.click()
      })
    },
    toggleNoteCategory(category) {
      // console.log('🐹 toggleCategory', category)
      const someOne = this.notes.openedNoteCategories.some(el => category === el)
      if (!someOne) {
        this.notes.openedNoteCategories.push(category)
      } else {
        const find = this.notes.openedNoteCategories.indexOf(category)
        this.notes.openedNoteCategories.splice(find, 1)
      }
    },
    setCurrentNote(note) {
      this.notes.currentNote = note
    },
    toggleBtnClose() {
      if (this.customTitle) {
        this.setCurrentNote(null)
      } else {
        this.$store.dispatch('app/TOGGLE_TOOLBAR')
      }
    },
    toggleQuickTransitionCategory(idx) {
      const someOne = this.qt.openQuickTransitionsCategories.some(el => idx === el)
      if (!someOne) {
        this.qt.openQuickTransitionsCategories.push(idx)
      } else {
        const find = this.qt.openQuickTransitionsCategories.indexOf(idx)
        this.qt.openQuickTransitionsCategories.splice(find, 1)
      }
    },
  },
}
</script>
