var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"mt-5"},_vm._l((4),function(i){return _c('pass-variant-picker',{key:i,class:`${_vm.getRandomStatus(i)} skeleton-variant`,attrs:{"without-anime":""},on:{"click":() => {}}},[_c('div',{staticClass:"skeleton-anime"},[_c('p',{staticClass:"opacity-0"},[_vm._v(" "+_vm._s(i)+" ")])])])}),1),_c('div',{staticClass:"mt-5"},[_c('skeleton-hs-main-button',{staticClass:"lg:w-72"},[_vm._v(" fake button ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"96%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"98%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"43%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"opacity-0"},[_vm._v(" br ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"86%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"90%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-anime mb-2",staticStyle:{"width":"71%"}},[_c('p',{staticClass:"opacity-0"},[_vm._v(" lorem text ")])])
}]

export { render, staticRenderFns }