import {onFieldChange} from '@/core/infrastructure/messaging/messaging'

const mixin = {
  computed: {
    currentFields() {
      return this.$store.getters.cache.currentWorkspace[this.uid].fields
    },
    getThisFieldVisibility() {
      return function(id) {
        if(!this.currentFields[id])
          return false
        if(!Object.prototype.hasOwnProperty.call(this.currentFields[id], 'visibility'))
          return true

        return this.currentFields[id]['visibility']
      }
    },
  },
  methods: {
    getThisField(id, key) {
      return this.currentFields[id] ? this.currentFields[id][key] : null
    },
    getThisFieldOptions(id) {
      return this.currentFields[id] ? this.currentFields[id].options.list_values : null
    },
    setValue(value, id) {
      this.$store.dispatch('cache/CHANGE_FIELD', { id, value, _uid: this.uid })
      onFieldChange({
        id: id,
        value: value,
        name: this.getThisField(id, 'name'),
        sys_name: this.getThisField(id, 'sys_name'),
      })
    },
  },
}

export default mixin
