import { GetDemoScript } from '../backend-endpoints.js'
import { generateStarredXstate,
         generateXstateSteps,
         convertScriptStepsToJSON,
         generateStarredForDemo,
         fixEmptyConnections,
         addValueFieldsToDemoScript } from '../../../../integrations/demo/helpers.js'

export default {
  namespaced: true,
  state: () => ({
    script: {},
  }),


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    SET_DEMO_SCRIPT(state, payload) {
      state.script = payload
    },
  },


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    async GET_DEMO_SCRIPT({ commit, dispatch }, payload) {
      const { id } = payload
      try {
        dispatch('app/SET_LOADING_DEMO_SCRIPT', true, { root: true })
        const response = await GetDemoScript(id)
        const script = response.data.response
        fixEmptyConnections(script.data)
        generateStarredForDemo(script)
        addValueFieldsToDemoScript(script)
        const xstates = generateXstateSteps(script.data)
        script.data.xstates = xstates
        const starredXstates = generateStarredXstate(script.data)
        script.data.starred_xstates = starredXstates
        convertScriptStepsToJSON(script.data)
        commit('SET_DEMO_SCRIPT', script)
        dispatch('app/SET_LOADING_DEMO_SCRIPT', false, { root: true })
      } catch (err) {
        console.error('🐹 demoScript/GET_DEMO_SCRIPT', err)
        dispatch('app/SET_LOADING_DEMO_SCRIPT', false, { root: true })
      }
    },
  },
}
