var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"hs-field-in-step field-material ring-0 ring-hs-primary bg-white",class:{
    'ring-2': _vm.isFocus
  },style:({
    'padding-top': '17px', 
    'border': '1px solid #f4f4f4',
    'border-radius': '10px'
  })},[_c('textarea',{staticClass:"textarea",style:({'padding-top': '0', 'border': 'none', 'min-height': '42px'}),attrs:{"id":_vm.id,"placeholder":""},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false},"input":function($event){return _vm.setValue($event)}}}),_vm._v(" "),_c('label',{staticClass:"label",class:{'is-focus': _vm.isFocus || _vm.valueIsNotEmpty},attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }