<template>
  <span class="hs-field-in-step field-material">
    <icon-base class="absolute left-3 top-2.5 text-hs-secondary">
      <icon-search :icon-name="$t('search')" />
    </icon-base>
    <input
      :id="id"
      :value="value"
      :placeholder="placeholder"
      style="padding-left: 50px;"
      @input="setValue($event)"
    >
    <icon-base
      v-if="isShowClearBtn"
      :width="18"
      :height="18"
      class="absolute right-3 top-4 text-hs-secondary cursor-pointer"
      @click.native="clearValue"
    >
      <icon-x :icon-name="$t('clear')" />
    </icon-base>
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'
import IconSearch from '@/core/design-system/icon-base/icons/search.vue'
import IconX from '@/core/design-system/icon-base/icons/x.vue'

export default {
  components: {
    IconSearch,
    IconX,
  },
  mixins: [FieldBaseMixin],
  computed: {
    isShowClearBtn() {
      return this.value ? this.value.length > 0 : false
    },
  },
}
</script>
