<template>
  <span :id="id" class="hs-field-in-step">
    <el-select
      v-model="localValue"
      :multiple="multiple"
      :placeholder="placeholder"
      :size="size"
      :style="fullWidth ? 'width: 100%' : ''"
    >
      <el-option
        v-for="(item, idx) in options"
        :key="idx"
        :label="item"
        :value="item"
      />
    </el-select>
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'

export default {
  mixins: [FieldBaseMixin],
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    size: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localValue: {
      get() { return this.value },
      set(val) { this.setValue(val) },
    },
  },
  methods: {
    setValue(val) {
      this.$emit('set-value', val)
    },
  },
}
</script>
