<template>
  <span class="hs-field-in-step">
    <input
      :id="id"
      :value="value"
      :placeholder="placeholder"
      :disabled="options.readonly"
      :type="textNumeric ? 'text' : 'number'"
      :inputmode="textNumeric ? 'numeric' : null"
      :digitOnly="textNumeric ? true : null"
      :style="fullWidth? 'width: 100%;' : ''"
      @input="setNumberValue($event)"
    >
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'
export default {
  mixins: [FieldBaseMixin],
  props: {
    options: {
      type: [Object, Array],
      default: () => ({}),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    textNumeric: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setNumberValue(event) {
     if (this.textNumeric) {
        if (event.target && event.target.value) {
          event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        }
      }
      this.setValue(event)
    },
  },
}
</script>
