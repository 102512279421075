export default {
  namespaced: true,
  state: () => ({
    theme: {
      scrollWidth: 0,
    },
    toolbar: {
      isOpen: false,
    },
    mainSidebar: {
      isOpen: false,
    },
    loginSidebar: {
      isOpen: false,
    },
    choosenTab: 0,
    isWaiting: true,
    isWaitingCurrentScript: false,
    isWaitingPassCallback: false,
    loadingDemoScript: true,
  }),


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    SET_SCROLL_WIDTH(state, payload) {
      state.theme.scrollWidth = payload
    },
    TOGGLE_TOOLBAR(state, payload) {
      state.toolbar.isOpen = payload
    },
    TOGGLE_SIDEBAR(state, payload) {
      const { sidebarKey, value } = payload
      state[sidebarKey].isOpen = value
    },
    SET_IS_WAITING_STATUS(state, payload) {
      state.isWaiting = payload
    },
    SET_IS_WAITING_CURRENT_SCRIPT_STATUS(state, payload) {
      state.isWaitingCurrentScript = payload
    },
    SET_IS_WAITING_PASS_CALLBACK(state, payload) {
      state.isWaitingPassCallback = payload
    },
    SET_CHOOSEN_TAB(state, payload) {
      state.choosenTab = payload
    },
    SET_LOADING_DEMO_SCRIPT(state, payload) {
      state.loadingDemoScript = payload
    },
  },


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    SET_SCROLL_WIDTH({ commit }, payload) {
      commit('SET_SCROLL_WIDTH', payload)
    },
    TOGGLE_TOOLBAR({ commit, state }, payload = null) {
      if (payload) {
        commit('TOGGLE_TOOLBAR', payload)
      } else {
        commit('TOGGLE_TOOLBAR', !state.toolbar.isOpen)
      }
    },
    TOGGLE_MAIN_SIDEBAR({ commit, state }, payload = null) {
      const pay = {
        sidebarKey: 'mainSidebar',
        value: payload,
      }
      if (payload !== null) {
        commit('TOGGLE_SIDEBAR', pay)
      } else {
        pay.value = !state[pay.sidebarKey].isOpen
        commit('TOGGLE_SIDEBAR', pay)
      }
    },
    TOGGLE_LOGIN_SIDEBAR({ commit, state }, payload = null) {
      const pay = {
        sidebarKey: 'loginSidebar',
        value: payload,
      }
      if (payload !== null) {
        commit('TOGGLE_SIDEBAR', pay)
      } else {
        pay.value = !state[pay.sidebarKey].isOpen
        commit('TOGGLE_SIDEBAR', pay)
      }
    },
    SET_IS_WAITING_STATUS({ commit }, payload) {
      commit('SET_IS_WAITING_STATUS', Boolean(payload))
    },
    SET_IS_WAITING_CURRENT_SCRIPT_STATUS({ commit }, payload) {
      commit('SET_IS_WAITING_STATUS', Boolean(payload))
    },
    SET_IS_WAITING_PASS_CALLBACK({ commit }, payload) {
      commit('SET_IS_WAITING_PASS_CALLBACK', Boolean(payload))
    },
    SET_CHOOSEN_TAB({ commit }, payload) {
      commit('SET_CHOOSEN_TAB', payload)
    },
    SET_LOADING_DEMO_SCRIPT({ commit }, payload) {
      commit('SET_LOADING_DEMO_SCRIPT', Boolean(payload))
    },
  },
}
