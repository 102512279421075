<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
    class="icon-base"
  >
    <title
      v-if="!hideTitle"
      :id="iconName"
      :lang="lang"
    >
      {{ iconName }}
    </title>
    <slot />
  </svg>
</template>


<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    viewBox: {
      type: String,
      default: '0 0 50 50',
    },
    iconName: {
      type: String,
      default: 'default',
    },
    lang: {
      type: String,
      default: 'ru',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
