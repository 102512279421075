import core from './application/views/application.vue'

import VueFriendlyIframe from 'vue-friendly-iframe'
// element-ui (https://github.com/ElemeFE/element)
import './application/utils/element.js'

import IconBase from './design-system/icon-base/icon-base.vue'
import HsInput from './design-system/form-elements/hs-input.vue'
import HsTextarea from './design-system/form-elements/hs-textarea.vue'
import HsTable from './design-system/form-elements/hs-table.vue'
import HsCheckbox from './design-system/form-elements/hs-checkbox.vue'
import HsNumber from './design-system/form-elements/hs-number.vue'
import HsDatetimePicker from './design-system/form-elements/hs-datetime-picker.vue'
import HsSelect from './design-system/form-elements/hs-select.vue'
import HsFormula from './design-system/form-elements/hs-formula.vue'

import featureToggling from './application/mixins/_feature-toggling-mixin.js'

/**
 * Styles
 */
import './assets/sass/global.sass'

export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    /**
     * Plugins
     */
    // vue-friendly-iframe (https://github.com/officert/vue-friendly-iframe)
    Vue.use(VueFriendlyIframe)

    /**
     * My global components
     */
    Vue.component('icon-base', IconBase)
    // fields
    Vue.component('hs-input', HsInput)
    Vue.component('hs-textarea', HsTextarea)
    Vue.component('hs-table', HsTable)
    Vue.component('hs-checkbox', HsCheckbox)
    Vue.component('hs-number', HsNumber)
    Vue.component('hs-datetime-picker', HsDatetimePicker)
    Vue.component('hs-select', HsSelect)
    Vue.component('hs-formula', HsFormula)

    /**
     * My global mixins
     */
    Vue.mixin(featureToggling)

    // Let's register our component globally
    // https://vuejs.org/v2/guide/components-registration.html
    Vue.component('hyperscript-pass-core', core)
  },
}
