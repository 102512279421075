<template>
    <transition name="fade">
      <div class="loading-script-preloader">
        <div class="h-16 text-hs-info mt-11 text-center flex items-center justify-center">
          <RingPreloader size="72px" color="#0F73C9"/>
        </div>
      </div>
    </transition>
  </template>

  <script>
    import RingPreloader from './ring-preloader.vue'
    export default {
      components: {
        RingPreloader,
      },
    }
  </script>
<style lang="sass" scoped>
.loading-script-preloader
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9999
  background: white
  display: flex
  justify-content: center
  align-items: center
</style>
