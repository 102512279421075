const mixin = {
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text)
      this.$store.dispatch('clipboard/SET_IS_TEXT_COPIED_TO_CLIPBOARD_CALLBACK', true)
      setTimeout(() => {
        this.$store.dispatch('clipboard/SET_IS_TEXT_COPIED_TO_CLIPBOARD_CALLBACK', false)
      }, 0)
    },
  },
}

export default mixin