<template>
  <div>
    <div
      v-for="(script, key) in currentHistoryLogWithIndex"
      :key="key"
    >
      <div
        v-for="(log, idx) in script"
        :key="idx"
        class="pl-5 lg:pl-8 pr-5 py-2"
      >
        <div v-if="isShowLog(key, script.length, idx)">
          <!-- Step text -->
          <pass-text
            :uid="key"
            :step="log.step"
          />
          <!-- /Step text -->
          <!-- Answer options -->
          <div class="mt-5">
            <pass-variant-picker
              v-for="(v, index) in log.variants"
              :key="index"
              :class="[`${ thisPrevToggle(v.id, log._idx) ? 'pass-variant-is-picked' : '' }`, v.status]"
              @click="toggleBackVariant(v.target, v.id, idx, key)"
            >
              {{ v.condition }}
            </pass-variant-picker>
          </div>
          <!-- /Answer options -->
          <!-- There is no correct answer -->
          <div class="mt-5">
            <hs-textarea-material
              v-if="checkNoAnswerFlag(log.step.id, key)"
              :id="log.step.id"
              :value="getNoAnswerValue(log.step.id, key)"
              :placeholder="$t('pass.appropriateResponseIsMissing.name')"
              @set-value="setNoAnswerValue($event, log.step.id, key)"
            />
            <p
              v-if="checkNoAnswerFlag(log.step.id, key)"
              class="text-xs mb-5 mt-2"
            >
              <i class="el-icon-info mr-1 text-hs-secondary relative" style="top: 1px;" />
              {{ $t('pass.appropriateResponseIsMissing.answerInTheEnd') }}
            </p>
          </div>
          <!-- /There is no correct answer -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PassVariantPicker from './pass-variant-picker.vue'
import HsTextareaMaterial from '@/core/design-system/form-elements/hs-textarea-material.vue'
import PassText from './pass-text.vue'

export default {
  components: {
    PassVariantPicker,
    HsTextareaMaterial,
    PassText,
  },
  computed: {
    currentHistoryLog() {
      return this.$parent.currentHistoryLog
    },
    currentHistoryLogWithIndex() {
      const arr = []
      let i = 0
      Object.entries(this.currentHistoryLog).forEach(([key, value]) => {
        const tmp = []
        value.forEach(v => {
          tmp.push({ ...v, _idx: i })
          i++
        })
        arr.push([key, tmp])
      })
      return Object.fromEntries(arr)
    },
    buttonClickHistory() {
      const arr = []
      const history = Object.values(this.currentHistoryLog).map(log => log.map(b => b._viaButton)).flat()
      for (let i = 1; i < history.length; i++) {
        arr.push(history[i])
      }
      return arr
    },
    currentScript() {
      return this.$parent.currentScript
    },
  },
  methods: {
    thisPrevToggle(buttonId, logIdx) {
      // console.log('🐹 buttonId, logIdx', buttonId, logIdx, buttonId === this.buttonClickHistory[logIdx])
      return buttonId === this.buttonClickHistory[logIdx]
    },
    toggleBackVariant(eventId, buttonId, logIndex, _scriptUid) {
      /**
       * eventId - step id
       * buttonId - link id
       * logIndex - index of the step from the history of the passages
       * _scriptUid - scripts mark in the history
       */
      this.$parent.backSend(eventId, buttonId, logIndex, _scriptUid)
    },
    checkNoAnswerFlag(stepId, _uid) {
      return this.$parent.checkNoAnswerFlag(stepId, _uid)
    },
    getNoAnswerValue(stepId, _uid) {
      return this.$parent.getNoAnswerValue(stepId, _uid)
    },
    setNoAnswerValue(value, stepId, _uid) {
      this.$parent.setNoAnswerValue(value, stepId, _uid)
    },
    isShowLog(key, length, idx) {
      return !(this.currentScript._uid === key && idx === length - 1)
    },
  },
}
</script>
