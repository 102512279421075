<template>
  <span class="hs-field-in-step field-material">
    <input
      :id="id"
      :value="value"
      placeholder=""
      :disabled="options.readonly"
      :type="textNumeric ? 'text' : 'number'"
      :inputmode="textNumeric ? 'numeric' : null"
      :digitOnly="textNumeric ? true : null"
      @focus="isFocus = true"
      @blur="isFocus = false"
      @input="setNumberValue($event)"
    >
    <label
      :for="id"
      :class="{'is-focus': isFocus || valueIsNotEmpty}"
    >
      {{ placeholder }}
    </label>
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'
export default {
  mixins: [FieldBaseMixin],
  props: {
    options: {
      type: [Object, Array],
      default: () => ({}),
    },
    textNumeric: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocus: this.value.length > 0,
    }
  },
  computed: {
    valueIsNotEmpty() {
      return this.value ? this.value.length > 0 : false
    },
  },
  methods: {
    setNumberValue(event) {
      if (this.textNumeric) {
        if (event.target && event.target.value) {
          event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        }
      }
      this.setValue(event)
    },
  },
}
</script>
