<template>
  <div
    class="relative w-full h-24 lg:h-20 p-4 rounded-xl text-xs lg:text-sm break-words"
    :class="{'opacity-20': disabled}"
  >
    <router-link
      :to="to"
      class="absolute top-0 left-0 w-full h-full p-4"
    >
      <p class="max-h-16 lg:max-h-12 line-clamp-3 lg:line-clamp-2">
        <slot />
      </p>
    </router-link>
    <div v-if="disabled" class="absolute z-10 top-0 left-0 w-full h-full cursor-not-allowed" />
  </div>
</template>


<script>
export default {
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'Scripts' }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
