export default {
    namespaced: true,
    state: () => ({
      isTextCopiedToClipboardCallback: false,
    }),

    /**
     * ---------------------------------------------------------------------------------------------------------------------------
     *  MUTATIONS
     * ---------------------------------------------------------------------------------------------------------------------------
     */
    mutations: {
      SET_IS_TEXT_COPIED_TO_CLIPBOARD_CALLBACK(state, payload) {
          state.isTextCopiedToClipboardCallback = payload
      },
    },
    
    /**
     * ---------------------------------------------------------------------------------------------------------------------------
     *  ACTIONS
     * ---------------------------------------------------------------------------------------------------------------------------
     */
    actions: {
      SET_IS_TEXT_COPIED_TO_CLIPBOARD_CALLBACK({ commit }, payload) {
        commit('SET_IS_TEXT_COPIED_TO_CLIPBOARD_CALLBACK', Boolean(payload))
      },
    },
}