<template>
  <div style="display: flex; flex-direction: row;">
    <div style="flex-grow: 1; width: 100%">
      <step-render
        :uid="uid"
        :json-text="step.text"
      />
    </div>
    <div class="pass-step-context-menu-button-wrapper">
      <context-menu-button
        :step="step"
        @click="onClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import StepRender from '../step-render.vue'
import ContextMenuButton from '../pass-step-context-menu/pass-step-context-menu-button.vue'

export default {
  components: {
    StepRender,
    ContextMenuButton,
  },

  props: {
    uid: {
      type: String,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
  },

  methods: {
    onClick() {
      this.$emit('toggleMenu')
    },
  },
}
</script>