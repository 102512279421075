const timerForSocket = {
  idle: 30,
  id: null,
}

let socket = null

// eslint-disable-next-line
export const initSocket = (userId, vm) => {
  console.debug('🔥 try init', userId)

  socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL)
  const send = function(message, callback) {
    waitForConnection(function() {
      socket.send(message)
      if (typeof callback !== 'undefined') {
        callback()
      }
    }, 1000)
  }

  const waitForConnection = function(callback, interval) {
    if (socket.readyState === 1) {
      callback()
    } else {
      setTimeout(function() {
        waitForConnection(callback, interval)
      }, interval)
    }
  }

  socket.onopen = () => {
    console.debug('🔥 socket.onopen', userId)
    console.debug('\t 🔥 CONNECTION SUCCESS')
    send(`{"uid":${userId}}`)
    if (!window._isOnline) {
      vm.$root.$emit('set-online-status', true)
    }
    // first timer
    clearTimeout(timerForSocket.id)
    timerForSocket.id = setTimeout(() => {
      console.debug('🔥 tick 30 sek - socket.onopen()')
      console.debug('\t 🔥 call $root.$emit(\'set-online-status\', false)')
      vm.$root.$emit('set-online-status', false)
    }, timerForSocket.idle * 1000)
  }

  socket.onmessage = event => {
    const checkForPong = JSON.parse(event.data).event
    if (checkForPong !== 'pong') {
      console.debug('🔥 Received data: ' + event.data)
      vm.socketCallbacks(event)
    }
    // pong detection
    clearTimeout(timerForSocket.id)
    timerForSocket.id = setTimeout(() => {
      console.debug('🔥 tick 30 sek - socket.onmessage()')
      console.debug('\t 🔥 call $root.$emit(\'set-online-status\', false)')
      vm.$root.$emit('set-online-status', false)
    }, timerForSocket.idle * 1000)
  }

  socket.onerror = error => {
    console.error('🔥 ERROR:', error.message)
  }

  socket.onclose = event => {
    // Connection closed.
    // Firstly, check the reason.
    closeSocket()

    clearTimeout(timerForSocket.id)
    timerForSocket.id = setTimeout(() => {
      console.debug('🔥 tick 30 sek - socket.onclose()')
      console.debug('\t 🔥 call initSocket()')
      initSocket(userId, vm)
    }, timerForSocket.idle * 1000)

    if (event.code != 1000) {
      // Error code 1000 means that the connection was closed normally.
      // Try to reconnect.
      if (!navigator.onLine) {
        console.error('🔥 ERROR:', 'You are offline. Please connect to the Internet and try again.')
      }
    }
  }

  window.socket_test = socket
}


export const closeSocket = () => {
  if (socket !== null) {
    console.debug('🔥 socket.close()')
    socket.close()
    socket = null
  }
}
