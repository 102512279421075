export default {
    namespaced: true,
    state: () => ({
      // step for menu object
      step: null,
    }),

    /**
     * ---------------------------------------------------------------------------------------------------------------------------
     *  MUTATIONS
     * ---------------------------------------------------------------------------------------------------------------------------
     */
    mutations: {
      SET_STEP(state, payload) {
          state.step = payload
      },
    },
    
    /**
     * ---------------------------------------------------------------------------------------------------------------------------
     *  ACTIONS
     * ---------------------------------------------------------------------------------------------------------------------------
     */
    actions: {
      SET_STEP({ commit }, payload) {
        commit('SET_STEP', payload)
      },
    },
}