<template>
  <div
    :class="{'open-item': maxHeight != '0px', 'disabledx': disabled}"
    class="vs-collapse-item"
    @mouseover="mouseover"
    @mouseout="mouseout"
  >
    <div class="qt-item">
      <header
        class="vs-collapse-item--header inline-flex"
        @click="toggleContent"
      >
        <slot name="header" />
        <icon-base
          v-if="!notArrow"
          class="icon-header ml-1"
          :icon-name="$t('hide')"
          :width="16"
          :height="16"
        >
          <icon-arrow-up />
        </icon-base>
      </header>
    </div>
    <div
      ref="content"
      :style="styleContent"
      class="vs-collapse-item--content"
    >
      <div class="con-content--item">
        <slot />
      </div>
    </div>
  </div>
</template>


<script>
import IconArrowUp from '@/core/design-system/icon-base/icons/arrow-up.vue'

export default {
  name: 'VsCollapseItem',
  components: {
    IconArrowUp,
  },
  props: {
    open: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default:false,
      type: Boolean,
    },
    notArrow: {
      default: false,
      type: Boolean,
    },
    iconArrow: {
      default: 'keyboard_arrow_down',
      type: String,
    },
    iconPack: {
      default: 'material-icons',
      type: String,
    },
    sst: {
      default: false,
      type: Boolean,
    },
    onCallback: {
      type: Function,
      default: () => { console.log('🐹 onCallback') },
    },
  },
  data: () => ({
    maxHeight: '0px',
    // only used for sst
    dataReady: false,
  }),
  computed: {
    accordion() {
      return this.$parent.accordion
    },
    openHover() {
      return this.$parent.openHover
    },
    styleContent() {
      return {
        maxHeight: this.maxHeight,
      }
    },
  },
  watch: {
    maxHeight() {
      this.$parent.emitChange()
    },
    ready(newVal, oldVal) {
      if (oldVal != newVal && newVal) {
        this.initMaxHeight()
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.changeHeight)
    const maxHeightx = this.$refs.content.scrollHeight || 0
    if(this.open) {
      this.maxHeight = `${maxHeightx}px`
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeHeight)
  },
  methods: {
    changeHeight() {
      const maxHeightx = this.$refs.content.scrollHeight || 0
      if(this.maxHeight != '0px') {
        this.maxHeight = `${maxHeightx}px`
      }
    },
    toggleContent() {
      if(this.openHover || this.disabled) return
      if(this.accordion) {
        this.$parent.closeAllItems(this.$el)
      }
      if (this.sst && !this.dataReady) {
        this.$emit('fetch', {
          done: () => {
            this.initMaxHeight()
            this.dataReady = true
          },
        })
      } else {
        this.initMaxHeight()
      }
      this.onCallback()
    },
    initMaxHeight() {
      const maxHeightx = this.$refs.content.scrollHeight || 0
      if(this.maxHeight == '0px') {
        this.maxHeight = `${maxHeightx}px`
      } else {
        this.maxHeight = '0px'
      }
    },
    mouseover() {
      if(this.disabled) return
      let maxHeightx = this.$refs.content.scrollHeight || 0
      if(this.openHover) {
        this.maxHeight = `${maxHeightx}px`
      }
    },
    mouseout() {
      if(this.openHover) {
        this.maxHeight = '0px'
      }
    },
  },
}
</script>
