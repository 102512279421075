<template>
  <el-tooltip
    :disabled="!isShowTooltip"
    effect="dark"
    :content="formulaLabel"
    placement="top"
  >
    <span
      class="hs-field-in-step is-formula-field"
      @click.ctrl="isShowTooltip = !isShowTooltip"
    >
      <input
        :id="id"
        :value="value"
        :placeholder="placeholder"
        readonly
        @input="setValue($event)"
      >
    </span>
  </el-tooltip>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'
export default {
  mixins: [FieldBaseMixin],
  props: {
    options: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  data: () => ({
    isShowTooltip: false,
    formula: [],
  }),
  computed: {
    formulaComputed() {
      const f = this.formula.map(e => typeof e === 'number' ? this.getFieldValueById(e): e)
      const str = f.join('')
      return eval(str)
    },
    formulaLabel() {
      const f = this.formula.map(e => typeof e === 'number' ? this.getFieldNameById(e): e)
      return f.join('')
    },
  },
  watch: {
    formulaComputed(val) {
      this.setValue(val)
    },
  },
  mounted() {
    if (this.options.numberFormula?.value) {
      const newDom = new DOMParser()
      const domObject = newDom.parseFromString(this.options.numberFormula?.value, 'text/html')
      domObject.childNodes[0].children[1].childNodes.forEach(e => {
        if (!e.data) {
          this.formula.push(parseInt(e.dataset.id, 10))
        } else {
          this.formula.push(e.data)
        }
      })
    }
  },
  methods: {
    getFieldValueById(id) {
      if (!this.$store.getters.cache.currentScript.fields[id]) {
        return 0
      }
      const num = Number(this.$store.getters.cache.currentScript.fields[id].value)
      return typeof num === 'number' ? num : 0
    },
    getFieldNameById(id) {
      if (!this.$store.getters.cache.currentScript.fields[id]) {
        return 'Not Found'
      }
      return this.$store.getters.cache.currentScript.fields[id].name
    },
    setValue(val) {
      this.$emit('set-value', val)
    },
  },
}
</script>
