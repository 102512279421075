<template>
  <button
    :class="`${customClass ? customClass : 'px-6 py-1 lg:py-2.5 font-bold rounded-xl text-base border-2'}  focus:outline-none focus:ring-2 focus:ring-opacity-50 transition-colors ${theme}`"
    :disabled="disabled"
    :type="type"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>


<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'default',
      validator: value => [
        'default',
        'outline',
        'primary',
        'success',
      ].indexOf(value) !== -1,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    themes: {
      default: 'focus:ring-hs-secondary',
      outline: 'border-hs-info focus:ring-hs-info hover:text-hs-secondary',
      primary: 'bg-hs-primary text-white border-hs-primary focus:ring-hs-primary hover:bg-hs-primary-hover hover:border-hs-primary-hover',
      success: 'bg-hs-success text-white border-hs-success focus:ring-hs-success hover:bg-hs-success-hover hover:border-hs-success-hover',
    },
  }),
  computed: {
    theme() { return this.themes[this.variant] },
  },
}
</script>
