<script>
import {authApi} from '@/core/application/components/auth/utils'

export default {
  name: 'IframeLogin',
  data() {
    return {}
  },
  methods: {
    async onSignIn(data) {
      console.debug('IFRAME onSignIn', data)
      try {
        await authApi.post(`${process.env.VUE_APP_IFRAME_API}/api/user/connect`, {
          externalUser: this.$route.query.user,
          apiKey: this.$route.query.api_key,
        }, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        await this.$router.push({name: 'iframeScripts', query: {user: this.$route.query.user, api_key: this.$route.query.api_key}})
      } catch (e) {
        console.error('IFRAME onSignIn ERROR', e)
      }
    },
  },
}
</script>

<template>
  <div class="login-form-container">
    <hyperscript-pass-core integration-type="iframe" :login-only="true" @on-sign-in="onSignIn($event)" />
  </div>
</template>

<style scoped lang="sass">
.login-form-container
  max-width: 1198px
  margin: auto
</style>
