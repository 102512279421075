/**
 * md5HashGenerate() - random token generation
 * @param {Number} id
 * @return {String} - random token
 */
import md5 from 'crypto-js/md5'
export const md5HashGenerate = id => {
  const hash = md5(id + 'p' + (Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000) + (new Date()).getTime())
  return hash.toString()
}


/**
 * timeDiffInSeconds() - time counting in seconds
 * @param {Date.now()} date1 - the number of milliseconds that have gone by since January 1, 1970
 * @param {Date.now()} date2 - milliseconds
 * @return {Number} - difference in seconds
 */
export const timeDiffInSeconds = (date1, date2) => {
  return Math.round((date2 - date1) / 1000)
}


/**
 * random() - proper random
 * @param {Number} min
 * @param {Number} max
 * @return {Number} - one value from the range [min, .. , max - 1]
 */
export const random = (min, max) => {
  // https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/random
  return Math.floor(Math.random() * (max - min) + min) // maximum is not on, minimum is on
}
