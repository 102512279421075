<template>
  <transition name="fade">
    <div id="hs-preloader">
      <div class="h-16 text-hs-info mt-11 text-center flex items-center justify-center">
        <div class="lds-ripple">
          <div />
          <div />
        </div>
        {{ $t('auth.loggingOut') }}..
      </div>
    </div>
  </transition>
</template>

<script>
  export default {}
</script>

<style scoped lang="scss">
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
