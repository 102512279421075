import axios from 'axios'

/**
 * axios config
 */
export const authApi = axios.create({
  baseURL: process.env.VUE_APP_AJAX_URL,
  withCredentials: true, // enabled hs cookies
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})


/**
 * jsonToFormData - format parser from JSON to FormData
 * @param {Object} obj
 * @return {FormData}
 */
export const jsonToFormData = obj => {
  const bodyFormData = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    bodyFormData.append(key, value)
  })
  return bodyFormData
}
