<script>
import api from '@/core/utils/axios.js'
export default {
  name: 'PipedriveLogin',
  data() {
    return {
      domain: null,
      userId: null,
      companyId: null,
    }
  },
  async mounted() {
    this.$nextTick(
      () => {
        this.domain = this.$route.query.domain
        this.userId = this.$route.query.userId
        this.companyId = this.$route.query.companyId
      },
    )
  },
  methods: {
    async onSignIn(data) {
      console.debug('PIPEDRIVE onSignIn')
      try {
        const response = await api.post(`${process.env.VUE_APP_PIPEDRIVE_API}/api/user/connect`, {
          domain: this.domain,
          userId: this.userId,
          companyId: this.companyId,
          userData: data,
        })
        if(!response.data.error) {
          window.location.href = this.domain
        }
      }
      catch (e) {
        console.error('PIPEDRIVE onSignIn ERROR', e)
      }
    },
  },
}
</script>

<template>
  <div class="login-form-container">
    <!--    Here goes a login form with pairing HS and pipedrive as a callback (pipedrive.hyper-script.com)-->
    <hyperscript-pass-core :login-only="true" @on-sign-in="onSignIn($event)" />
  </div>
</template>

<style scoped lang="sass">
.login-form-container
  max-width: 1198px
  margin: auto
</style>
