<template>
  <div class="skeleton">
    <div
      v-for="i in 6"
      :key="i"
      class="qt-item"
    >
      <div class="skeleton-anime" :style="`width: ${random()}%`">
        <p
          class="inline font-normal cursor-pointer text-base select-none opacity-0"
        >
          {{ i }}
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import { random } from '@/core/infrastructure/helpers/helpers.js'


export default {
  methods: {
    random() {
      return random(60, 100)
    },
  },
}
</script>
