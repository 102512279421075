import Vue from 'vue'
export const notificationBus = new Vue({
  methods: {
    notify(obj) {
      this.$emit('notify', obj)
    },
  },
})

// EventBus (https://alligator.io/vuejs/global-event-bus/)
// import { notificationBus } from '@/notification-bus.js'
