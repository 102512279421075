export default {
  namespaced: true,
  state: () => ({
    isAuthorized: false,
    userInfo: {
      id: 0,
    },
  }),


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    AUTHORIZATION(state, payload) {
      // console.log('🐹 AUTHORIZATION', payload)
      if (payload.token) {
        const { data } = payload
        state.userInfo = data
        state.isAuthorized = true
      }
    },
    CHANGE_STATUS(state, payload) {
      state.isAuthorized = payload
    },
    KILL_CURRENT_USER(state) {
      state.userInfo = {
        id: 0,
      }
    },
  },


  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    AUTHORIZATION({ commit }, payload) {
      commit('AUTHORIZATION', payload)
    },
    CHANGE_STATUS({ commit }, payload) {
      commit('CHANGE_STATUS', payload)
    },
    KILL_CURRENT_USER({ commit }) {
      commit('KILL_CURRENT_USER')
    },
  },
}
