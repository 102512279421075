<script>
import AppExtensionsSDK from '@pipedrive/app-extensions-sdk'
import {Event} from '@pipedrive/app-extensions-sdk'
import api from '@/core/utils/axios'

export default {
  name: 'EntryPoint',
  data() {
    return {
      context: {},
    }
  },
  computed: {
    isAuthorized() {
      return this.$store.getters.auth.isAuthorized || false
    },
  },
  mounted() {
    (async() => {
      // SDK detects identifier from URL and uses default custom UI size
      const sdk = await new AppExtensionsSDK().initialize({ size: { height: 700, width: 800 } })
      sdk.listen(Event.VISIBILITY, ({error, data}) => {
        console.log(Event.VISIBILITY, error, data)
      })
      if(!this.isAuthorized) {
        console.debug('PIPEDRIVE IS NOT AUTHENTICATED')
        try {
          await api.post(`${process.env.VUE_APP_PIPEDRIVE_API}/api/user/authenticate`, {
            token: this.$route.query.token,
            userId: this.$route.query.userId,
            companyId: this.$route.query.companyId,
          })

          await this.$store.dispatch('app/TOGGLE_LOGIN_SIDEBAR', false)
          setTimeout(() =>  {
            this.$root.$emit('getUserData')
          }, 1000)
        } catch (e) {
          console.error('PIPEDRIVE AUTHENTICATION error', e)
        }
      }
    })()
    // TODO: handle pipedrive entity
    // TODO: get entity from pipedrive and inject field values to 'passer'

    // TODO: once script passed send data to API
  },
}
</script>

<template>
  <div>
    <hyperscript-pass-core integration-type="pipedrive" />
  </div>
</template>

<style scoped>

</style>
