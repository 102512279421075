import EntryPoint from '../views/entrypoint.vue'

const routes = [
  {
    path: '/demo/script/:id',
    component: EntryPoint,
  },
]

export default routes
