<template>
  <label :id="id" class="hs-checkbox">
    <input
      :value="value"
      type="checkbox"
      :checked="value === '1'"
      true-value="1"
      false-value=""
      @input="setValue($event)"
    >
    <span>{{ placeholder }}</span>
  </label>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'

export default {
  mixins: [FieldBaseMixin],
  methods: {
    setValue(event) {
      this.$emit('set-value', event.target.checked ? '1' : '')
    },
  },
}
</script>
