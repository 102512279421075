const DB_NAME = 'hsIndexedDB'
const DB_VERSION = 3

const STORAGE_OPENED_SCRIPTS = 'opened_scripts'
const STORAGE_STARRED_SCRIPTS = 'starred_scripts'
const STORAGE_OF_BACKUPS = 'backups'

let DB

export default {
  async getDatabase() {
    return new Promise((resolve, reject) => {
      // if it was already open
      if (DB) { return resolve(DB) }
      /**
       * IndexedDB - https://learn.javascript.ru/indexeddb#otkryt-bazu-dannyh
       */
      // opening indexedDB
      const request = window.indexedDB.open(DB_NAME, DB_VERSION)

      /**
       * onerror() - failed to open the database
       * @param {Event} e
       */
      request.onerror = e => {
        console.error('Error opening db', e)
        reject(e)
      }
      /**
       * onsuccess() - the database is ready to work, the "database object" is ready
       * request.result, it should be used for further calls
       * @param {Event} e
       */
      request.onsuccess = e => {
        DB = e.target.result
        resolve(DB)
      }
      /**
       * onupgradeneeded() - the database is open, but its scheme is outdated
       * @param {Event} e
       */
      request.onupgradeneeded = e => {
        let db = request.result
        if (e.oldVersion === 0) {
          db.createObjectStore(STORAGE_OPENED_SCRIPTS, { autoIncrement: true, keyPath: 'id' })
          db.createObjectStore(STORAGE_OF_BACKUPS, { autoIncrement: true, keyPath: 'id' })
          db.createObjectStore(STORAGE_STARRED_SCRIPTS, { keyPath: 'userId' })
        } else {
          if (!db.objectStoreNames.contains(STORAGE_STARRED_SCRIPTS)) {
            db.createObjectStore(STORAGE_STARRED_SCRIPTS, { keyPath: 'userId' })
          }
          if (!db.objectStoreNames.contains(STORAGE_OPENED_SCRIPTS)) {
            db.createObjectStore(STORAGE_OPENED_SCRIPTS, { autoIncrement: true, keyPath: 'id' })
          }
          if (!db.objectStoreNames.contains(STORAGE_OF_BACKUPS)) {
            db.createObjectStore(STORAGE_OF_BACKUPS, { autoIncrement: true, keyPath: 'id' })
          }
        }
      }
    })
  },
  async getOpenedScripts() {
    let db = await this.getDatabase()

    return new Promise(resolve => {
      let trans = db.transaction([STORAGE_OPENED_SCRIPTS], 'readonly')
      trans.oncomplete = () => {
        resolve(scripts)
      }
      const store = trans.objectStore(STORAGE_OPENED_SCRIPTS)
      const scripts = []
      store.openCursor().onsuccess = e => {
        const cursor = e.target.result
        if (cursor) {
          scripts.push(cursor.value)
          cursor.continue()
        }
      }
    })
  },
  async setOpenedScript(script) {
    let db = await this.getDatabase()

    return new Promise(resolve => {
      let trans = db.transaction([STORAGE_OPENED_SCRIPTS], 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      let store = trans.objectStore(STORAGE_OPENED_SCRIPTS)
      store.put(script)
    })
  },
  async deleteOpenedScript(script) {
    const db = await this.getDatabase()
    return new Promise(resolve => {
      const trans = db.transaction([STORAGE_OPENED_SCRIPTS], 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      const store = trans.objectStore(STORAGE_OPENED_SCRIPTS)
      store.delete(script.id)
    })
  },

  async getStarredScripts(userId) {
    let db = await this.getDatabase()
    return new Promise(resolve => {
      const trans = db.transaction([STORAGE_STARRED_SCRIPTS], 'readonly')
      const store = trans.objectStore(STORAGE_STARRED_SCRIPTS)
      const request = store.get(userId)
      request.onsuccess = () => {
        if (request.result !== undefined) {
          resolve(request.result.scripts)
        } else {
          resolve([])
        }
      }
    })
  },

  async setStarredScript(userId, script) {
    let db = await this.getDatabase()

    return new Promise(resolve => {
      const trans = db.transaction([STORAGE_STARRED_SCRIPTS], 'readonly')
      const store = trans.objectStore(STORAGE_STARRED_SCRIPTS)
      const request = store.get(userId)
      request.onsuccess = () => {
        if (request.result !== undefined) {
          resolve(request.result.scripts)
        } else {
          resolve([])
        }
      }
    }).then((scripts) => {
      return new Promise(resolve => {
        if(!scripts.includes(script)) {
          scripts.push(script)
          const starredScripts = {
            userId,
            scripts,
          }
          const trans = db.transaction([STORAGE_STARRED_SCRIPTS], 'readwrite')
          const store = trans.objectStore(STORAGE_STARRED_SCRIPTS)
          const request = store.put(starredScripts)
          request.onsuccess = () => {
            resolve()
          }
        } else {
          resolve()
        }
      })
    })
  },

  async deleteStarredScript(userId, script) {
    let db = await this.getDatabase()

    return new Promise(resolve => {
      const trans = db.transaction([STORAGE_STARRED_SCRIPTS], 'readonly')
      const store = trans.objectStore(STORAGE_STARRED_SCRIPTS)
      const request = store.get(userId)
      request.onsuccess = () => {
        if (request.result !== undefined) {
          resolve(request.result.scripts)
        } else {
          resolve([])
        }
      }
    }).then((scripts) => {
      return new Promise(resolve => {
        scripts = scripts.filter((s) => s !== script)
        const starredScripts = {
          userId,
          scripts,
        }
        const trans = db.transaction([STORAGE_STARRED_SCRIPTS], 'readwrite')
        const store = trans.objectStore(STORAGE_STARRED_SCRIPTS)
        const request = store.put(starredScripts)
        request.onsuccess = () => {
          resolve()
        }
      })
    })
  },

  async getBackups() {
    let db = await this.getDatabase()

    return new Promise(resolve => {
      let trans = db.transaction([STORAGE_OF_BACKUPS], 'readonly')
      trans.oncomplete = () => {
        resolve(scripts)
      }
      const store = trans.objectStore(STORAGE_OF_BACKUPS)
      const scripts = []
      store.openCursor().onsuccess = e => {
        const cursor = e.target.result
        if (cursor) {
          scripts.push(cursor.value)
          cursor.continue()
        }
      }
    })
  },
  async setBackup(script) {
    let db = await this.getDatabase()

    return new Promise(resolve => {
      let trans = db.transaction([STORAGE_OF_BACKUPS], 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      let store = trans.objectStore(STORAGE_OF_BACKUPS)
      store.put(script)
    })
  },
  async deleteBackup(script) {
    const db = await this.getDatabase()
    return new Promise(resolve => {
      const trans = db.transaction([STORAGE_OF_BACKUPS], 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      const store = trans.objectStore(STORAGE_OF_BACKUPS)
      store.delete(script.id)
    })
  },
}
