export default {
    namespaced: true,
    state: () => ({
      features: {},
    }),

    /**
     * ---------------------------------------------------------------------------------------------------------------------------
     *  MUTATIONS
     * ---------------------------------------------------------------------------------------------------------------------------
     */
    mutations: {
      TOGGLE(state, payload) {
          state.features[payload.feature] = payload.status
      },
    },
    
    /**
     * ---------------------------------------------------------------------------------------------------------------------------
     *  ACTIONS
     * ---------------------------------------------------------------------------------------------------------------------------
     */
    actions: {
      TURN_ON({ commit }, feature) {
        console.log('🐹 featureToggling/TURN_ON ' + feature)
        commit('TOGGLE', {
          feature: feature, 
          status: true,
        })
      },

      TURN_OFF({ commit }, feature) {
        console.log('🐹 featureToggling/TURN_OFF ' + feature)
        commit('TOGGLE', {
          feature: feature, 
          status: false,
        })
      },
    },
}